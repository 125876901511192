import React from 'react';
import '../styling/home.css'
import {Container, Row, Col} from 'react-bootstrap'
import ProjectCard from '../components/projectCards'
import Fade from 'react-reveal/Fade'
import Kingston from '../images/Kingston/bird.jpg'
import Kingrose1 from '../images/Kingrose/Front_465.jpg'
import Omorose from '../images/Omorose/08.jpg'
import Farmville from '../images/Farmville/farmville_gate.jpg'
import Lifestyle from '../images/Lifestyle/1_465.jpg'
import Sky from '../images/Sky/birdview.jpg'
import Heights from '../images/Heights/heights_card_465.jpg'
import Shopping from '../images/99Shopping/card_465.jpg'
import Idealhomes from '../images/Idealhomes/4bhk_465.jpg'
import Township from '../images/Township/05_465.jpg'
import {Helmet} from 'react-helmet'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-135620216-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const paths = [
"/kingston", "/omorose", "/farmville", "/lifestyle", "/heights", "/sky", "/99shopping", "/township", "/idealhomes", "/kingrose"
];
const imgSrc = [
  Kingston, Omorose, Farmville, Lifestyle, Heights, Sky, Shopping, Township, Idealhomes, Kingrose1
];
const cardTitle = [
"Kingston", "Omorose", "Vaishnodevi Farmville", "Vaishnodevi Lifestyle", "Vaishnodevi Heights", "Vaishnodevi Sky", "99 Shopping - By Vaishnodevi Group", "Vaishnodevi Township", "Vaishnodevi Ideal Home", "Kingrose Villa 1"
];
const cardText = [
  "IT'S THE NOBLE CONCEPT TO LIVE NATURE. IT'S NONE OTHER THAN YOUR KINGSTON.",
  "THE VIEW IS ENOUGH TO SEND THE VIBES OF GRANDIOSE. WELCOME TO YOUR OMOROSE.",
  "TAKE SOME TIME OFF YOUR BUSY LIFE AND COME ENJOY THE TRANQUILITY OF NATURE.",
  "THE HI-END LUXURY BECKONS YOU TO ITS GRAND, ELEGANT, AND CHARISMATIC LOOKS.",
  "BECAUSE IT WILL MAKE YOUR DREAM OF ACCOMPLISHING NEW HEIGHTS COME TRUE.",
  "A DREAM HOME IS LIKE A LIFE IN HEAVEN...THAT IS RIGHT HERE...RIGHT NOW",
  "A PRODUCTIVITY ENHANCING PLACE FOR YOUR SHOPS. TAKE YOUR BUSINESS TO A NEW LEVEL.",
  "A SENSE OF PEACE AND HARMONY WILL PERVADE AS YOU SIT AND BREATHE IN THE SURROUNDINGS CREATED.",
  "THE IDEAL LOCATION AND ENVIRONMENT TEMPTS YOU TO LIVE HERE.",
  "A FULFILLING PROJECT COMPRISING OF MORE THAN 50 PLOTS WHICH GOT SOLD OUT IN A BLINK OF AN EYE."
];

function Completed() {
  return (
    <div className="completed mt-5">
    <Helmet>
      <meta description="Vaishnodevi Group. Vaishnodevi Realty" />
      <title>
        Completed Projects
      </title>
    </Helmet>
      <Container fluid className="pt-5 pb-5 lightBlock">
      <div class="row justify-content-center lightTextNoMargin w-100 ml-0">
              <div class="col-lg-12">
                  <h3 class="lightText text-center">Completed Projects</h3>
              </div>
        </div>
        <Row className="w-100 ml-auto mr-auto">
          <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[9]} projectPath={paths[9]} projectImage={imgSrc[9]} projectText={cardText[9]}>
              </ProjectCard>
            </Fade>
          </Col>
          <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[0]} projectPath={paths[0]} projectImage={imgSrc[0]} projectText={cardText[0]}>
              </ProjectCard>
            </Fade>
          </Col>
          <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[1]} projectPath={paths[1]} projectImage={imgSrc[1]} projectText={cardText[1]}>
              </ProjectCard>
            </Fade>
          </Col>
        </Row>
        <Row className="w-100 ml-auto mr-auto">
        <Col md={true} className="col-lg-4">
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[2]} projectPath={paths[2]} projectImage={imgSrc[2]} projectText={cardText[2]}>
              </ProjectCard>
            </Fade>
          </Col>
        <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[3]} projectPath={paths[3]} projectImage={imgSrc[3]} projectText={cardText[3]}>
              </ProjectCard>
            </Fade>
          </Col>
        <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[4]} projectPath={paths[4]} projectImage={imgSrc[4]} projectText={cardText[4]}>
              </ProjectCard>
            </Fade>
          </Col>
        </Row>
        <Row className="w-100 ml-auto mr-auto pb-3">
          <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[5]} projectPath={paths[5]} projectImage={imgSrc[5]} projectText={cardText[5]}>
              </ProjectCard>
            </Fade>
          </Col>
          <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[6]} projectPath={paths[6]} projectImage={imgSrc[6]} projectText={cardText[6]}>
              </ProjectCard>
            </Fade>
          </Col>
          <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[7]} projectPath={paths[7]} projectImage={imgSrc[7]} projectText={cardText[7]}>
              </ProjectCard>
            </Fade>
          </Col>
          <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[8]} projectPath={paths[8]} projectImage={imgSrc[8]} projectText={cardText[8]}>
              </ProjectCard>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Completed;
