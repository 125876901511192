import KingsleySampleB1 from '../images/Kingsley_B_3bhk_Sample/b_3bhk.jpg'
import KingsleySampleB2 from '../images/Kingsley_B_3bhk_Sample/b_3bhk_2.jpg'
import KingsleySampleB3 from '../images/Kingsley_B_3bhk_Sample/b_3bhk_3.jpg'
import KingsleySampleB4 from '../images/Kingsley_B_3bhk_Sample/b_3bhk_4.jpg'
import KingsleySampleB5 from '../images/Kingsley_B_3bhk_Sample/b_3bhk_5.jpg'
import KingsleySampleB6 from '../images/Kingsley_B_3bhk_Sample/b_3bhk_6.jpg'
import KingsleySampleB7 from '../images/Kingsley_B_3bhk_Sample/b_3bhk_7.jpg'

import KingsleyImage1 from '../images/Kingsley/Building_View_01.jpg'
import KingsleyImage2 from '../images/Kingsley/Cam_01.jpg'
import KingsleyImage3 from '../images/Kingsley/Cam_01_Night.jpg'
import KingsleyImage4 from '../images/Kingsley/Cam_03.jpg'
import KingsleyImage5 from '../images/Kingsley/Deck_View.jpg'
import KingsleyImage6 from '../images/Kingsley/foyar.jpg'
import KingsleyImage7 from '../images/Kingsley/Gate_view.jpg'
import KingsleyImage8 from '../images/Kingsley/jogging_track.jpg'
import KingsleyImage9 from '../images/Kingsley/jogging_track_day.jpg'
import KingsleyImage10 from '../images/Kingsley/Top_View.jpg'
import KingsleyImage11 from '../images/Kingsley/Volleyball_Court.jpg'
import KingsleyImage1Thumbnail from '../images/Kingsley/Building_View_01.jpg'
import KingsleyImage2Thumbnail from '../images/Kingsley/Cam_01.jpg'
import KingsleyImage3Thumbnail from '../images/Kingsley/Cam_01_Night.jpg'
import KingsleyImage4Thumbnail from '../images/Kingsley/Cam_03.jpg'
import KingsleyImage5Thumbnail from '../images/Kingsley/Deck_View.jpg'
import KingsleyImage6Thumbnail from '../images/Kingsley/foyar.jpg'
import KingsleyImage7Thumbnail from '../images/Kingsley/Gate_view.jpg'
import KingsleyImage8Thumbnail from '../images/Kingsley/jogging_track.jpg'
import KingsleyImage9Thumbnail from '../images/Kingsley/jogging_track_day.jpg'
import KingsleyImage10Thumbnail from '../images/Kingsley/Top_View.jpg'
import KingsleyImage11Thumbnail from '../images/Kingsley/Volleyball_Court.jpg'
import KingsleyImage1Medium from '../images/Kingsley/Building_View_01.jpg'
import KingsleyImage2Medium from '../images/Kingsley/Cam_01.jpg'
import KingsleyImage3Medium from '../images/Kingsley/Cam_01_Night.jpg'
import KingsleyImage4Medium from '../images/Kingsley/Cam_03.jpg'
import KingsleyImage5Medium from '../images/Kingsley/Deck_View.jpg'
import KingsleyImage6Medium from '../images/Kingsley/foyar.jpg'
import KingsleyImage7Medium from '../images/Kingsley/Gate_view.jpg'
import KingsleyImage8Medium from '../images/Kingsley/jogging_track.jpg'
import KingsleyImage9Medium from '../images/Kingsley/jogging_track_day.jpg'
import KingsleyImage10Medium from '../images/Kingsley/Top_View.jpg'
import KingsleyImage11Medium from '../images/Kingsley/Volleyball_Court.jpg'

import KingstonImage1 from '../images/Kingston/back.jpg'
import KingstonImage2 from '../images/Kingston/bird.jpg'
import KingstonImage3 from '../images/Kingston/children_area.jpg'
import KingstonImage4 from '../images/Kingston/garden1.jpg'
import KingstonImage5 from '../images/Kingston/garden2.jpg'
import KingstonImage6 from '../images/Kingston/mp.jpg'
import KingstonImage7 from '../images/Kingston/mp_theatre.jpg'
import KingstonImage8 from '../images/Kingston/night.jpg'
import KingstonImage9 from '../images/Kingston/night_top.jpg'
import KingstonImage10 from '../images/Kingston/night_waterfall.jpg'
import KingstonImage11 from '../images/Kingston/swimming.jpg'
import KingstonImage12 from '../images/Kingston/swimming2.jpg'
import KingstonImage13 from '../images/Kingston/terrace_garden.jpg'
import KingstonImage14 from '../images/Kingston/top_garden.jpg'
import KingstonImage15 from '../images/Kingston/water2.jpg'
import KingstonImage16 from '../images/Kingston/waterfall_view.jpg'
import KingstonImage1Thumbnail from '../images/Kingston/back.jpg'
import KingstonImage2Thumbnail from '../images/Kingston/bird.jpg'
import KingstonImage3Thumbnail from '../images/Kingston/children_area.jpg'
import KingstonImage4Thumbnail from '../images/Kingston/garden1.jpg'
import KingstonImage5Thumbnail from '../images/Kingston/garden2.jpg'
import KingstonImage6Thumbnail from '../images/Kingston/mp.jpg'
import KingstonImage7Thumbnail from '../images/Kingston/mp_theatre.jpg'
import KingstonImage8Thumbnail from '../images/Kingston/night.jpg'
import KingstonImage9Thumbnail from '../images/Kingston/night_top.jpg'
import KingstonImage10Thumbnail from '../images/Kingston/night_waterfall.jpg'
import KingstonImage11Thumbnail from '../images/Kingston/swimming.jpg'
import KingstonImage12Thumbnail from '../images/Kingston/swimming2.jpg'
import KingstonImage13Thumbnail from '../images/Kingston/terrace_garden.jpg'
import KingstonImage14Thumbnail from '../images/Kingston/top_garden.jpg'
import KingstonImage15Thumbnail from '../images/Kingston/water2.jpg'
import KingstonImage16Thumbnail from '../images/Kingston/waterfall_view.jpg'
import KingstonImage1Medium from '../images/Kingston/back.jpg'
import KingstonImage2Medium from '../images/Kingston/bird.jpg'
import KingstonImage3Medium from '../images/Kingston/children_area.jpg'
import KingstonImage4Medium from '../images/Kingston/garden1.jpg'
import KingstonImage5Medium from '../images/Kingston/garden2.jpg'
import KingstonImage6Medium from '../images/Kingston/mp.jpg'
import KingstonImage7Medium from '../images/Kingston/mp_theatre.jpg'
import KingstonImage8Medium from '../images/Kingston/night.jpg'
import KingstonImage9Medium from '../images/Kingston/night_top.jpg'
import KingstonImage10Medium from '../images/Kingston/night_waterfall.jpg'
import KingstonImage11Medium from '../images/Kingston/swimming.jpg'
import KingstonImage12Medium from '../images/Kingston/swimming2.jpg'
import KingstonImage13Medium from '../images/Kingston/terrace_garden.jpg'
import KingstonImage14Medium from '../images/Kingston/top_garden.jpg'
import KingstonImage15Medium from '../images/Kingston/water2.jpg'
import KingstonImage16Medium from '../images/Kingston/waterfall_view.jpg'

import OmoroseImage1 from '../images/Omorose/01.jpg'
import OmoroseImage2 from '../images/Omorose/02.jpg'
import OmoroseImage3 from '../images/Omorose/03.jpg'
import OmoroseImage4 from '../images/Omorose/04.jpg'
import OmoroseImage5 from '../images/Omorose/05.jpg'
import OmoroseImage6 from '../images/Omorose/06.jpg'
import OmoroseImage7 from '../images/Omorose/07.jpg'
import OmoroseImage8 from '../images/Omorose/08.jpg'
import OmoroseImage9 from '../images/Omorose/09.jpg'
import OmoroseImage10 from '../images/Omorose/10.jpg'
import OmoroseImage11 from '../images/Omorose/11.jpg'
import OmoroseImage12 from '../images/Omorose/12.jpg'
import OmoroseImage13 from '../images/Omorose/13.jpg'
import OmoroseImage14 from '../images/Omorose/14.jpg'
import OmoroseImage15 from '../images/Omorose/15.jpg'
import OmoroseImage16 from '../images/Omorose/16.jpg'
import OmoroseImage17 from '../images/Omorose/17.jpg'
import OmoroseImage18 from '../images/Omorose/18.jpg'
import OmoroseImage19 from '../images/Omorose/Cam_Bird_18.jpg'
import OmoroseImage1Thumbnail from '../images/Omorose/01.jpg'
import OmoroseImage2Thumbnail from '../images/Omorose/02.jpg'
import OmoroseImage3Thumbnail from '../images/Omorose/03.jpg'
import OmoroseImage4Thumbnail from '../images/Omorose/04.jpg'
import OmoroseImage5Thumbnail from '../images/Omorose/05.jpg'
import OmoroseImage6Thumbnail from '../images/Omorose/06.jpg'
import OmoroseImage7Thumbnail from '../images/Omorose/07.jpg'
import OmoroseImage8Thumbnail from '../images/Omorose/08.jpg'
import OmoroseImage9Thumbnail from '../images/Omorose/09.jpg'
import OmoroseImage10Thumbnail from '../images/Omorose/10.jpg'
import OmoroseImage11Thumbnail from '../images/Omorose/11.jpg'
import OmoroseImage12Thumbnail from '../images/Omorose/12.jpg'
import OmoroseImage13Thumbnail from '../images/Omorose/13.jpg'
import OmoroseImage14Thumbnail from '../images/Omorose/14.jpg'
import OmoroseImage15Thumbnail from '../images/Omorose/15.jpg'
import OmoroseImage16Thumbnail from '../images/Omorose/16.jpg'
import OmoroseImage17Thumbnail from '../images/Omorose/17.jpg'
import OmoroseImage18Thumbnail from '../images/Omorose/18.jpg'
import OmoroseImage19Thumbnail from '../images/Omorose/Cam_Bird_18.jpg'
import OmoroseImage1Medium from '../images/Omorose/01.jpg'
import OmoroseImage2Medium from '../images/Omorose/02.jpg'
import OmoroseImage3Medium from '../images/Omorose/03.jpg'
import OmoroseImage4Medium from '../images/Omorose/04.jpg'
import OmoroseImage5Medium from '../images/Omorose/05.jpg'
import OmoroseImage6Medium from '../images/Omorose/06.jpg'
import OmoroseImage7Medium from '../images/Omorose/07.jpg'
import OmoroseImage8Medium from '../images/Omorose/08.jpg'
import OmoroseImage9Medium from '../images/Omorose/09.jpg'
import OmoroseImage10Medium from '../images/Omorose/10.jpg'
import OmoroseImage11Medium from '../images/Omorose/11.jpg'
import OmoroseImage12Medium from '../images/Omorose/12.jpg'
import OmoroseImage13Medium from '../images/Omorose/13.jpg'
import OmoroseImage14Medium from '../images/Omorose/14.jpg'
import OmoroseImage15Medium from '../images/Omorose/15.jpg'
import OmoroseImage16Medium from '../images/Omorose/16.jpg'
import OmoroseImage17Medium from '../images/Omorose/17.jpg'
import OmoroseImage18Medium from '../images/Omorose/18.jpg'
import OmoroseImage19Medium from '../images/Omorose/Cam_Bird_18.jpg'


import FarmvilleImage1 from '../images/Farmville/bird_view.jpg'
import FarmvilleImage2 from '../images/Farmville/c_sector.jpg'
import FarmvilleImage3 from '../images/Farmville/club.jpg'
import FarmvilleImage4 from '../images/Farmville/farmville_gate.jpg'
import FarmvilleImage5 from '../images/Farmville/golf.jpg'
import FarmvilleImage6 from '../images/Farmville/lake_day.jpg'
import FarmvilleImage7 from '../images/Farmville/night_view.jpg'
import FarmvilleImage8 from '../images/Farmville/night_view_gate.jpg'
import FarmvilleImage9 from '../images/Farmville/swimming_pool.jpg'
import FarmvilleImage10 from '../images/Farmville/swimming_pool_night.jpg'
import FarmvilleImage1Thumbnail from '../images/Farmville/bird_view.jpg'
import FarmvilleImage2Thumbnail from '../images/Farmville/c_sector.jpg'
import FarmvilleImage3Thumbnail from '../images/Farmville/club.jpg'
import FarmvilleImage4Thumbnail from '../images/Farmville/farmville_gate.jpg'
import FarmvilleImage5Thumbnail from '../images/Farmville/golf.jpg'
import FarmvilleImage6Thumbnail from '../images/Farmville/lake_day.jpg'
import FarmvilleImage7Thumbnail from '../images/Farmville/night_view.jpg'
import FarmvilleImage8Thumbnail from '../images/Farmville/night_view_gate.jpg'
import FarmvilleImage9Thumbnail from '../images/Farmville/swimming_pool.jpg'
import FarmvilleImage10Thumbnail from '../images/Farmville/swimming_pool_night.jpg'
import FarmvilleImage1Medium from '../images/Farmville/bird_view.jpg'
import FarmvilleImage2Medium from '../images/Farmville/c_sector.jpg'
import FarmvilleImage3Medium from '../images/Farmville/club.jpg'
import FarmvilleImage4Medium from '../images/Farmville/farmville_gate.jpg'
import FarmvilleImage5Medium from '../images/Farmville/golf.jpg'
import FarmvilleImage6Medium from '../images/Farmville/lake_day.jpg'
import FarmvilleImage7Medium from '../images/Farmville/night_view.jpg'
import FarmvilleImage8Medium from '../images/Farmville/night_view_gate.jpg'
import FarmvilleImage9Medium from '../images/Farmville/swimming_pool.jpg'
import FarmvilleImage10Medium from '../images/Farmville/swimming_pool_night.jpg'

import KingsleySampleD1 from '../images/Kingsley_D_3bhk_Sample/d_3bhk.jpg'
import KingsleySampleD2 from '../images/Kingsley_D_3bhk_Sample/d_3bhk_2.jpg'
import KingsleySampleD3 from '../images/Kingsley_D_3bhk_Sample/d_3bhk_3.jpg'
import KingsleySampleD4 from '../images/Kingsley_D_3bhk_Sample/d_3bhk_4.jpg'
import KingsleySampleD5 from '../images/Kingsley_D_3bhk_Sample/d_3bhk_5.jpg'
import KingsleySampleD6 from '../images/Kingsley_D_3bhk_Sample/d_3bhk_6.jpg'
import KingsleySampleD7 from '../images/Kingsley_D_3bhk_Sample/d_3bhk_7.jpg'

/**
 *     {
        projectTitle: 'Kingrose',
        numImages: 6,
        images: [
            {original: KingroseImage1, thumbnail: KingroseImage1Thumbnail, originalClass: 'galleryImage'}, 
            {original: KingroseImage2, thumbnail: KingroseImage2Thumbnail, originalClass: 'galleryImage'}, 
            {original: KingroseImage3, thumbnail: KingroseImage3Thumbnail, originalClass: 'galleryImage'}, 
            {original: KingroseImage4, thumbnail: KingroseImage4Thumbnail, originalClass: 'galleryImage'},
            {original: KingroseImage5, thumbnail: KingroseImage5Thumbnail, originalClass: 'galleryImage'}, 
            {original: KingroseImage6, thumbnail: KingroseImage6Thumbnail, originalClass: 'galleryImage'}
        ]
    },
 * 
 */

export const GalleryPageData = [
    {
        projectTitle: 'Kingsley 3-BHK Sample Flat (B-Building)',
        numImages: 7,
        images: [
            {original: KingsleySampleB1, thumbnail: KingsleySampleB1, originalClass: 'galleryImage'},
            {original: KingsleySampleB2, thumbnail: KingsleySampleB2, originalClass: 'galleryImage'},
            {original: KingsleySampleB3, thumbnail: KingsleySampleB3, originalClass: 'galleryImage'},
            {original: KingsleySampleB4, thumbnail: KingsleySampleB4, originalClass: 'galleryImage'},
            {original: KingsleySampleB5, thumbnail: KingsleySampleB5, originalClass: 'galleryImage'},
            {original: KingsleySampleB6, thumbnail: KingsleySampleB6, originalClass: 'galleryImage'},
            {original: KingsleySampleB7, thumbnail: KingsleySampleB7, originalClass: 'galleryImage'},
        ]
    },
    {
        projectTitle: 'Kingsley 3-BHK Sample Flat (D-Building)',
        numImages: 7,
        images: [
            {original: KingsleySampleD1, thumbnail: KingsleySampleD1, originalClass: 'galleryImage'},
            {original: KingsleySampleD2, thumbnail: KingsleySampleD2, originalClass: 'galleryImage'},
            {original: KingsleySampleD3, thumbnail: KingsleySampleD3, originalClass: 'galleryImage'},
            {original: KingsleySampleD4, thumbnail: KingsleySampleD4, originalClass: 'galleryImage'},
            {original: KingsleySampleD5, thumbnail: KingsleySampleD5, originalClass: 'galleryImage'},
            {original: KingsleySampleD6, thumbnail: KingsleySampleD6, originalClass: 'galleryImage'},
            {original: KingsleySampleD7, thumbnail: KingsleySampleD7, originalClass: 'galleryImage'},
        ]
    },
    {
        projectTitle: 'Kingsley',
        numImages: 11,
        images: [
            {original: KingsleyImage1, thumbnail: KingsleyImage1Thumbnail, 
                    originalClass: 'galleryImage', imageSet: [
                        {
                            srcSet: KingsleyImage1Medium,
                            media : '(max-width: 600px)',
                        }, 
                        {
                            srcSet: KingsleyImage1,
                            media : '(min-width: 601px)',
                        }]}, 
            {original: KingsleyImage2, thumbnail: KingsleyImage2Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingsleyImage2Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingsleyImage2,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingsleyImage3, thumbnail: KingsleyImage3Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingsleyImage3Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingsleyImage3,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingsleyImage4, thumbnail: KingsleyImage4Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingsleyImage4Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingsleyImage4,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingsleyImage5, thumbnail: KingsleyImage5Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingsleyImage5Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingsleyImage5,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingsleyImage6, thumbnail: KingsleyImage6Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingsleyImage6Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingsleyImage6,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingsleyImage7, thumbnail: KingsleyImage7Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingsleyImage7Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingsleyImage7,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingsleyImage8, thumbnail: KingsleyImage8Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingsleyImage8Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingsleyImage8,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingsleyImage9, thumbnail: KingsleyImage9Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingsleyImage9Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingsleyImage9,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingsleyImage10, thumbnail: KingsleyImage10Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingsleyImage10Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingsleyImage10,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingsleyImage11, thumbnail: KingsleyImage11Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingsleyImage11Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingsleyImage11,
                        media : '(min-width: 601px)',
                    }]}, 
        ]
    },
    {
        projectTitle: 'Kingston',
        numImages: 16,
        images: [
            {original: KingstonImage1, thumbnail: KingstonImage1Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage1Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage1,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage2, thumbnail: KingstonImage2Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage2Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage2,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage3, thumbnail: KingstonImage3Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage3Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage3,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage4, thumbnail: KingstonImage4Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage4Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage4,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage5, thumbnail: KingstonImage5Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage5Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage5,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage6, thumbnail: KingstonImage6Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage6Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage6,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage7, thumbnail: KingstonImage7Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage7Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage7,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage8, thumbnail: KingstonImage8Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage8Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage8,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage9, thumbnail: KingstonImage9Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage9Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage9,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage10, thumbnail: KingstonImage10Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage10Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage10,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage11, thumbnail: KingstonImage11Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage11Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage11,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage12, thumbnail: KingstonImage12Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage12Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage12,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage13, thumbnail: KingstonImage13Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage13Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage13,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage14, thumbnail: KingstonImage14Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage14Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage14,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage15, thumbnail: KingstonImage15Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage15Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage15,
                        media : '(min-width: 601px)',
                    }]}, 
            {original: KingstonImage16, thumbnail: KingstonImage16Thumbnail,
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: KingstonImage16Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: KingstonImage16,
                        media : '(min-width: 601px)',
                    }]}
        ]
    },
    {
        projectTitle: 'Omorose',
        numImages: 18,
        images: [
            {original: OmoroseImage1, thumbnail: OmoroseImage1Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage1Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage1,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage2, thumbnail: OmoroseImage2Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage2Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage2,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage3, thumbnail: OmoroseImage3Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage3Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage3,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage4, thumbnail: OmoroseImage4Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage4Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage4,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage5, thumbnail: OmoroseImage5Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage5Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage5,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage6, thumbnail: OmoroseImage6Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage6Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage6,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage7, thumbnail: OmoroseImage7Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage7Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage7,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage8, thumbnail: OmoroseImage8Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage8Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage8,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage9, thumbnail: OmoroseImage9Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage9Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage9,
                        media : '(min-width: 601px)',
                    }]},   
            {original: OmoroseImage10, thumbnail: OmoroseImage10Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage10Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage10,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage11, thumbnail: OmoroseImage11Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage11Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage11,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage12, thumbnail: OmoroseImage12Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage12Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage12,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage13, thumbnail: OmoroseImage13Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage13Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage13,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage14, thumbnail: OmoroseImage14Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage14Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage14,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage15, thumbnail: OmoroseImage15Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage15Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage15,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage16, thumbnail: OmoroseImage16Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage16Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage16,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage17, thumbnail: OmoroseImage17Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage17Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage17,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage18, thumbnail: OmoroseImage18Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage18Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage18,
                        media : '(min-width: 601px)',
                    }]},  
            {original: OmoroseImage19, thumbnail: OmoroseImage19Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: OmoroseImage19Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: OmoroseImage19,
                        media : '(min-width: 601px)',
                    }]},  
        ]
    },
    {
        projectTitle: 'Vaishnodevi Farmville',
        numImages: 10,
        images: [
            {original: FarmvilleImage1, thumbnail: FarmvilleImage1Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: FarmvilleImage1Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: FarmvilleImage1,
                        media : '(min-width: 601px)',
                    }]},  
            {original: FarmvilleImage2, thumbnail: FarmvilleImage2Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: FarmvilleImage2Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: FarmvilleImage2,
                        media : '(min-width: 601px)',
                    }]},   
            {original: FarmvilleImage3, thumbnail: FarmvilleImage3Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: FarmvilleImage3Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: FarmvilleImage3,
                        media : '(min-width: 601px)',
                    }]},  
            {original: FarmvilleImage4, thumbnail: FarmvilleImage4Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: FarmvilleImage4Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: FarmvilleImage4,
                        media : '(min-width: 601px)',
                    }]},  
            {original: FarmvilleImage5, thumbnail: FarmvilleImage5Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: FarmvilleImage5Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: FarmvilleImage5,
                        media : '(min-width: 601px)',
                    }]},  
            {original: FarmvilleImage6, thumbnail: FarmvilleImage6Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: FarmvilleImage6Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: FarmvilleImage6,
                        media : '(min-width: 601px)',
                    }]},  
            {original: FarmvilleImage7, thumbnail: FarmvilleImage7Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: FarmvilleImage7Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: FarmvilleImage7,
                        media : '(min-width: 601px)',
                    }]},  
            {original: FarmvilleImage8, thumbnail: FarmvilleImage8Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: FarmvilleImage8Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: FarmvilleImage8,
                        media : '(min-width: 601px)',
                    }]},  
            {original: FarmvilleImage9, thumbnail: FarmvilleImage9Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: FarmvilleImage9Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: FarmvilleImage9,
                        media : '(min-width: 601px)',
                    }]},  
            {original: FarmvilleImage10, thumbnail: FarmvilleImage10Thumbnail, 
                originalClass: 'galleryImage', imageSet: [
                    {
                        srcSet: FarmvilleImage10Medium,
                        media : '(max-width: 600px)',
                    }, 
                    {
                        srcSet: FarmvilleImage10,
                        media : '(min-width: 601px)',
                    }]},  
        ]
    }
]