import React from 'react';
import { Carousel,  Card, Col, Row } from 'react-bootstrap'
import { LazyImageProvider } from "../LazyImage/lazyImageContext"; 
import ProjectCard from '../components/projectCards'
import LazyImage from '../LazyImage/lazyImage'
import '../styling/home.css'
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom';
import Jogging_Track from '../images/Kingswood/carousel5.jpg'
import Jogging_Track465 from '../images/Kingswood/carousel5.jpg'
import Jogging_TrackMobile from '../images/Kingswood/carousel5.jpg'
import KingswoodEntrance from '../images/Kingswood/kingswood_entrance.jpg'
import KingswoodBuildings from '../images/Kingswood/carousel2.jpg'
import Terrace from '../images/Kingswood/view_01_new.jpg' 
import Building_View from '../images/Residency/08.jpg'
import Front from '../images/Kingrose/Front_465.jpg'
import KingsleyBuilding from '../images/Kingsley/Building_View_01.jpg'
import {Helmet} from 'react-helmet'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-135620216-1');
ReactGA.pageview(window.location.pathname + window.location.search);


const images = [
  Jogging_Track, Terrace, KingswoodBuildings
];

const mediumImages = [
  Jogging_Track465,  Terrace, KingswoodBuildings
];

const mobileImages = [
  Jogging_TrackMobile,  Terrace, KingswoodBuildings
];

const paths = [
  "/kingsley", "/kingswood", "/kingrose2", "/residency"
  ];
  const imgSrc = [
    KingsleyBuilding, KingswoodEntrance, Front, Building_View
  ];
  const cardTitle = [
    "Kingsley", "Kingswood", "Kingrose Villa 2", "Vaishnodevi Residency"
  ];
  const cardText = [
    "The kingdom of modern luxuries. 3 & 4-bhk luxurious flats and 5-bhk penthouses. ", 
    "Extremely spacious and luxurious 2 & 3-bhk flats mindfully designed for you keeping your welfare and well-beign in mind.",
    "Kingrose Villa 2 is a plotting scheme comprising of more than 50 plots. ",
    "One of the most affordable housing scheme for people who have always dreamt of having a home of their own."
  ];

function Ongoing() {
  return (
    <div className="ongoing">
         <Helmet>
            <meta description="Best Luxurious Flats in Surat, Gujarat." />
            <title>
              On-going Projects
            </title>
          </Helmet>

      <LazyImageProvider>
          <Carousel style={{marginTop: '5em'}} interval={8000} className="bigCarousel">
            {images.map((image, index) => (
                <Carousel.Item>
                  <LazyImage aspectRatio={[21, 10]} src={image} key={index} mediumSrc={mediumImages[index]} mobileSrc={mobileImages[index]}/>
                </Carousel.Item>
              ))}
          </Carousel>
      </LazyImageProvider>
    
      
      <div class="container-fluid lightBlock">
          <div class="row justify-content-center lightTextNoMargin w-100 ml-0">
              <div class="col-lg-12">
                  <h3 class="lightText text-center">On-Going Projects</h3>
              </div>
          </div>
          <Row className="w-100 ml-auto mr-auto pb-3">
          <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[0]} projectPath={paths[0]} projectImage={imgSrc[0]} projectText={cardText[0]}>
              </ProjectCard>
            </Fade>
          </Col>
          <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[1]} projectPath={paths[1]} projectImage={imgSrc[1]} projectText={cardText[1]}>
              </ProjectCard>
            </Fade>
          </Col>
          <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[2]} projectPath={paths[2]} projectImage={imgSrc[2]} projectText={cardText[2]}>
              </ProjectCard>
            </Fade>
          </Col>
          <Col md={true}>
            <Fade bottom>
              <ProjectCard projectTitle={cardTitle[3]} projectPath={paths[3]} projectImage={imgSrc[3]} projectText={cardText[3]}>
              </ProjectCard>
            </Fade>
          </Col>
        </Row>
      </div>    
    </div>
  );
}

export default Ongoing;
