import KingsleyLogo from '../images/logo/Kingsley.png'
import KingsleyProjectImage from '../images/Kingsley/Cam_01.jpg'
import KingsleyLayout1 from '../images/Kingsley/Layout/img44.jpg'
import KingsleyLayout2 from '../images/Kingsley/Layout/img46.jpg'
import KingsleyLayout3 from '../images/Kingsley/Layout/img48.jpg'
import KingsleyLayout4 from '../images/Kingsley/Layout/img50.jpg'
import KingsleyLayout5 from '../images/Kingsley/Layout/img52.jpg'
import KingstonLogo from '../images/logo/Kingston.png'
import kingstonProjectImage from '../images/Kingston/garden1.jpg'
import kingstonLayout1 from '../images/Kingston/Layout/general.jpg'
import kingstonLayout2 from '../images/Kingston/Layout/bl_4bhk.jpg'
import kingstonLayout3 from '../images/Kingston/Layout/bl_4bhk_pent.jpg'
import kingstonLayout4 from '../images/Kingston/Layout/blm_4bhk.jpg'
import kingstonLayout5 from '../images/Kingston/Layout/cdk_4bhk.jpg'
import kingstonLayout6 from '../images/Kingston/Layout/cdk_4bhk_pent.jpg'
import kingstonLayout7 from '../images/Kingston/Layout/efghij_3bhk.jpg'
import kingstonLayout8 from '../images/Kingston/Layout/efghij_3bhk_pent.jpg'
import OmoroseLogo from '../images/logo/Omorose.png'
import FarmvilleLogo from '../images/logo/Farmville.png'
import LifestyleLogo from '../images/logo/Lifestyle.png'
import HeightsLogo from '../images/logo/Heights.png'
import SkyLogo from '../images/logo/Sky.png'
import ShoppingLogo from '../images/logo/99_Shopping.png'
import TownshipLogo from '../images/logo/Township.png'
import IdealhomesLogo from '../images/logo/Ideal_Homes.png'
import KingroseLogo from '../images/logo/Kingrose_villa.png'
import Kingrose2Logo from '../images/logo/kingrose2.png'
import OmoroseProjectImage from '../images/Omorose/03.jpg'
import OmoroseLayout1 from '../images/Omorose/Layouts/layout1.jpg'
import OmoroseLayout2 from '../images/Omorose/Layouts/layout2.jpg'
import OmoroseLayout3 from '../images/Omorose/Layouts/layout3.jpg'
import OmoroseLayout4 from '../images/Omorose/Layouts/layout4.jpg'
import KingswoodLogo from '../images/logo/kingswood.png'
import KingswoodProjectImage from '../images/Kingswood/viiew_05_Budhha_New.jpg'
import KingswoodLayout1 from '../images/Kingswood/Layouts/3bhk.jpg'
import KingswoodLayout5 from '../images/Kingswood/Layouts/floor.jpg'
import KingswoodLayout2 from '../images/Kingswood/Layouts/b2bhk.jpg'
import KingswoodLayout3 from '../images/Kingswood/Layouts/c22bhk.jpg'
import KingswoodLayout4 from '../images/Kingswood/Layouts/c132bhk.jpg'
import KingswoodBrochureFile from '../resources/Kingswood.pdf'
import FarmvilleProjectImage from '../images/Farmville/swimming_pool_night.jpg'
import FarmvilleLayout1 from '../images/Farmville/Layouts/typeA.jpg'
import FarmvilleLayout2 from '../images/Farmville/Layouts/typeB.jpg'
import FarmvilleLayout3 from '../images/Farmville/Layouts/typeC.jpg'
import FarmvilleLayout4 from '../images/Farmville/Layouts/typeD.jpg'
import FarmvilleLayout5 from '../images/Farmville/Layouts/typeE.jpg'
import LifestyleProjectImage from '../images/Lifestyle/1_465.jpg'
import LifestyleLayout1 from '../images/Lifestyle/Layouts/016.jpg'
import LifestyleLayout2 from '../images/Lifestyle/Layouts/017.jpg'
import LifestyleLayout3 from '../images/Lifestyle/Layouts/018.jpg'
import LifestyleLayout4 from '../images/Lifestyle/Layouts/019.jpg'
import LifestyleLayout5 from '../images/Lifestyle/Layouts/020.jpg'
import LifestyleLayout6 from '../images/Lifestyle/Layouts/021.jpg'
import LifestyleLayout7 from '../images/Lifestyle/Layouts/022.jpg'
import HeightsProjectImage from '../images/Heights/heights_card_465.jpg'
import HeightsLayout1 from '../images/Heights/Layouts/6.jpg'
import HeightsLayout2 from '../images/Heights/Layouts/7.jpg'
import HeightsLayout3 from '../images/Heights/Layouts/8.jpg'
import HeightsLayout4 from '../images/Heights/Layouts/9.jpg'
import SkyProjectImage from '../images/Sky/05.jpg'
import SkyLayout1 from '../images/Sky/Layouts/016.jpg'
import SkyLayout2 from '../images/Sky/Layouts/017.jpg'
import SkyLayout3 from '../images/Sky/Layouts/018.jpg'
import ShoppingProjectImage from '../images/99Shopping/001_465.jpg'
import IdealhomesProjectImage from '../images/Idealhomes/night_465.jpg'
import TownshipProjectImage from '../images/Township/03_465.jpg'
import KingroseProjectImage from '../images/Kingrose/Garden_view.jpg'
import KingroseLayout1 from '../images/Kingrose/Layouts/street.jpg'
import KingroseLayout2 from '../images/Kingrose/Layouts/street.jpg'
import KingroseLayout3 from '../images/Kingrose/Layouts/street.jpg'
import KingroseLayout4 from '../images/Kingrose/Layouts/street.jpg'
import Kingrose2Layout1 from '../images/Kingrose2/plot_layout.jpg'
import Kingrose2Layout2 from '../images/Kingrose2/plot_size.jpg'
import KingsleyBrochureFile from '../resources/Kingsley.pdf'
import KingstonBrochureFile from '../resources/kingston.pdf'
import KingroseBrochureFile from '../resources/Kingrose_Villa.pdf'
import Kingrose2BrochureFile from '../resources/Kingrose_Villa_2.pdf'
import OmoroseBrochureFile from '../resources/omorose.pdf'
import FarmvilleBrochureFile from '../resources/Vaishnodevi_Farmville.pdf'
import LifestyleBrochureFile from '../resources/Vaishnodevi_Lifestyle.pdf'
import HeightsBrochureFile from '../resources/Vaishnodevi_Heights.pdf'
import SkyBrochureFile from '../resources/Vaishnodevi_Sky.pdf'
import ShoppingBrochureFile from '../resources/Vaishnodevi_99shopping.pdf'
import TownshipBrochureFile from '../resources/Vaishnodevi_Township.pdf'
import IdealhomesBrochureFile from '../resources/Vaishnodevi_IdealHomes.pdf'
import ResidencyLogo from '../images/logo/residency.png'
import ResidencyProjectImage from '../images/Residency/09.jpg'
import ResidencyLayout1 from '../images/Residency/Layouts/Typical.jpg'
import ResidencyLayout2 from '../images/Residency/Layouts/ab2bhk.jpg'
import ResidencyLayout3 from '../images/Residency/Layouts/cdgh2bhk.jpg'
import ResidencyLayout4 from '../images/Residency/Layouts/ef2bhk.jpg'
import VaishnodeviResidencyBrochure from '../resources/Residency.pdf'


export const ProjectPageData = [
    {
        logo: KingsleyLogo,
        title: 'Kingsley',
        backgroundMode: 'lightBlock',
        funding: 'This project is funded by Aditya Birla Housing Finance Limited.',
        backgroundText: 'lightTextNoMargin',
        showDetails: true,
        logoAltText: 'Kingsley Logo',
        projectName: 'Kingsley',
        backgroundClassName: 'kingsleyBackground',
        projectInfo: [
            "Located in the Pal, Adajan area of Surat, Kinglsey is possibly the best opportunity to buy a luxurious flat at the best available rate. It consists of 3 & 4 bhk flats on the first 11 floors. The 12th and 13th floors combined forms an ultra-luxurious pent house of 3 bhk as well as 4 bhk.", 
            "The motivation behind this project was the grand success of our previous project Kingston. It was clear that the location was extremely provoking and it led to a surgin demand for flats in that area, despite the demand being less elsewhere.",
            'Guj Rera Reg. number :- PR/GJ/SURAT/CHORASI/SUDA/RAA05756/190719',
            'Guj Rera Auth. Website:- www.gujrera.gujarat.gov.in',
        ],
        projectImage: KingsleyProjectImage,
        projectDetails: [
            [
                'Total number of buildings: 4',
                'No. of Floors: 2 LEVEL BASEMENT PARKING + GROUND FLOOR + TYPICAL 1ST TO 11TH FLOOR + PENT-HOUSE ON 12TH AND 13TH FLOORS',
                'Types of flats: 3 & 4 bhk luxurious flats, and 5 bhk pent-house.',
                'Number of flats per floor: 2',
                'Location: Pal, Adajan, Surat'
            ],
            [
                'Designated Use: Residential High Rise.',
                'Location: Village Bhatha',
                'No. of Floors: 2 level basement parking + ground floor + typical 1st to 11th floor + pent-house on 12th and 13th floors',
                'Total Car Parks: 2 cars per flat and 4 cars per pent-house.',
                'Block: Block 5 no. of buildings in campus (4 buildings in phase 01 and 1 building in phase 2 for future development)',
                'Slab to Slab Floor Height: 3.19 meter',
                'Building Structure: RCC building designed as per IS codes. Buildings have been designed as per IS codes 456 (concrete), IS 875 (Design) and IS 1893 (Seismic)'
            ],
        ],
        projectDetailsTitle: [
            'Overview', 'Features'
        ],
        layoutExists: true,
        layoutUrl: [
            KingsleyLayout1,
             KingsleyLayout2, KingsleyLayout3, KingsleyLayout4, KingsleyLayout5
        ],
        projectSummary : [
            'Residential', 'On-Going', 'Pal, Adajan'
        ],
        brochure: 'kingsleyBrochure',
        brochureUrl: KingsleyBrochureFile,
        brochureDownloadText: 'Kingsley',
        location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11199.110664443013!2d72.76343771545014!3d21.187394824600155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04df6ece8e681%3A0x631c07c1414628b7!2sVaishnodevi+Kingsley!5e1!3m2!1sen!2sin!4v1564369804284!5m2!1sen!2sin'
    },
    {
        logo: KingstonLogo,
        backgroundMode: 'lightBlock',
        showDetails: true,
        title: 'Kingston',
        backgroundText: 'lightTextNoMargin',
        logoAltText: 'Kingston Logo',
        projectName: 'Kingston',
        backgroundClassName: 'kingstonBackground',
        projectInfo: [
            "It's the stately paradise to live luxury. It's none other than your Kingston.",
            "The level of actual attainment towards life always lies in the cent per cent extravagance in living and spending it. Surat's highly famed developers with their distinctive projects came up with their superbly modeled venture; Kingston: The Grand Life.",
            "The revelations of natural blue green shades of atmosphere are immensely pleasurable. The beauty re-invents the culture of green living with splendid custom in addition to it.",
            "It's the noble concept to live nature. It's none other than your Kingston."
        ],
        projectImage: kingstonProjectImage,
        projectDetails: [
            [
                "TOTAL NUMBER OF BUILDINGS: 13",
                "NUMBER OF FLOORS: BASEMENT + GROUND + 12 FLOORS",
                "TYPES OF FLATS: 3 & 4 BHK LUXURIOUS FLATS.",
                "NUMBER OF FLATS PER FLOOR: 2",
                "LOCATION: PAL, ADAJAN, SURAT."
            ],
            [
                "DESIGNATED USE: RESIDENTIAL HIGH-RISE",
                "LOCATION BLOCK: 138 VILLAGE BHATHA",
                "TOTAL CAR PARKS: 3 CARS PERMITTED FOR 4BHK RESIDENTS & 2 CARS PERMITTED FOR 3BHK RESIDENTS",
                "SPACE SPECIFICATIONS AT GROUND: HOLLOW PLINTH FOR PARKING (BASEMENT & GROUND FLOOR)",
                "SLAB TO SLAB FLOOR HEIGHTS: 3.05 METRE",
                "BUILDING STRUCTURE: RCC FRAME BUILDING DESIGNED AS PER IS CODES. BUILDING HAS BEEN DESIGNED AS PER IS CODES 456 (CONCRETE), IS 857 (DESIGN) AND IS 1893 (SEISMIC)"
            ],
            [
                "INDOOR GAMES: POOL TABLE, CARROM BOARD, CHESS BOARD & TABLE TENNIS",
                "OUTDOOR GAMES: LAWN TENNIS COURT, VOLLEYBALL COURT, BASKETBALL COURT, BADMINTION COURT, SKATING AREA, NET CRICKET",
                "UNISEX SALON",
                "LIBRARY WITH SEATING AREA HAVING UPDATED MAGAZINES AND BOOKS AND SENIOR ENTERTAINMENT AREA",
                "BANQUET HALL WITH CAPACITY OF HAVING 100 PEOPLE FOR SOCIAL FUNCTIONS",
                "GYM WITH STANDARD QUALITY QUIPMENTS. SPA: STEAM, SAUNA, JACUZZI & MASSAGE CENTER",
                "GREEN SPACE, FLOOR FOUNTAIN, CHILDREN PLAY EQUIPMENTS, AND SEATING AREA FOR PARENTS",
                "COVERED SWIMMING POOL + WHIRPOOL + RAIN BATH",
                "APPROX 1,00,000 SQ. FT. OF CENTRAL GARDEN, GAZEBOS, GLORIUS WATER CASCADE & CLUB HOUSE, AMBHITHEATRE, SPORTS COURT, JAIN DERASAR & RADHA KRISHNA TEMPLE",
                "MUSIC, YOGA, MEDITATION, AEROBICS, RELIGIOUS TEACHING ON ROOF TOP AREA. HOME THEATRE, DISCOTHEQUE, PARTY LOUNGE IN BASEMENT."
            ]
        ],
        projectDetailsTitle: [
            'Overview', 'Features', 'Amenities'
        ],
        layoutExists: true,
        layoutUrl: [
            kingstonLayout1,
             kingstonLayout2, kingstonLayout3, kingstonLayout4, kingstonLayout5, kingstonLayout6, kingstonLayout7, kingstonLayout8
        ],
        projectSummary : [
            'Residential', 'Completed', 'Pal, Adajan'
        ],
        brochure: 'kingstonBrochure',
        brochureUrl: KingstonBrochureFile,
        brochureDownloadText: 'Vaishnodevi kingston',
        location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.766140413115!2d72.76344051493533!3d21.188570785912013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04dadc234868b%3A0x99d8415b5bb1b7a!2sKingston!5e1!3m2!1sen!2sin!4v1564286152467!5m2!1sen!2sin'
    },
    {
        logo: OmoroseLogo,
        title: 'Omorose',
        backgroundMode: 'lightBlock',
        showDetails: true,
        backgroundText: 'lightTextNoMargin',
        logoAltText: 'Omorose Logo',
        projectName: 'Omorose',
        backgroundClassName: 'OmoroseBackground',
        projectInfo: [
            "OMOROSE ~ A BLOOMING PICTURE OF HAPPINESS",
            "A look at Omorose is enough to make you envisage the grand life you are going to spend here. The imposing structure of Omorose is a testimony that here dwells the life beyond ordinary.",
			"The amenities at Omorose simply complement the contemporary and urban living that we follow. Equipped with some refreshing and rejuvenating amenities like Skating and Gym it offers an invigorating living experience for one and all."
        ],
        projectImage: OmoroseProjectImage,
        projectDetails: [
            [
                    "Total number of buildings: 2",
                    "Number of floors: Basement + Ground Floor + Typical 1st to 12th Floors (Building A) + Typical 1st to 11th Floors (Building B)",
                    "Types of flats: 3 & 4 bhk ultra-luxurious flats.",
                    "Number of flats per floor: 2",
                    "Location: Pal, Adajan, Surat."
            ],
            [
                    "Designated Use: Residential High-rise",
                    "Location: F.P. - 25, T.P. - 14 (Pal)",
                    "total car parks: 3 cars permitted for 4bhk residents & 2 cars permitted for 3bhk residents",
                    "space specifications at ground: hollow plinth for parking (basement & ground floor)",
                    "slab to slab floor heights: 3.05 metre",
                    "building structure: rcc frame building designed as per IS codes. Building has been designed as per IS codes 456 (concrete), IS 857 (design) and IS 1893 (Seismic)"
            ],
            [
                    "Multisports zone: Volleyball court, Basketball court, badminton court, skating rink",
                    "Children play area: green space built with rubber matt floor for children play equipments & floor fountain area.",
                    "Banquet hall: Banquet hall for social functions like birthday party, anniversary, engagement, & other events with party lawns.",
                    "Central garden: The central garden is a huge lush green patch.",
                    "Open gym & spa: Open gym with standard quality of modern equipments with steam, sauna & jacuzzi",
                    "Indoor games: Pool table, carrom board, chess board, table tennis and soccer table",
                    "Yoga center: Music, meditation, aerobics & religious teachings",
                    "Roof top garden: Landscaped gardens with gazebos and open mini theatre"
            ]
        ],
        projectDetailsTitle: [
            'Overview', 'Features', 'Amenities'
        ],
        layoutExists: true,
        layoutUrl: [
            OmoroseLayout1,
             OmoroseLayout2, OmoroseLayout3, OmoroseLayout4
        ],
        projectSummary : [
            'Residential', 'Completed', 'Pal, Adajan'
        ],
        brochure: 'OmoroseBrochure',
        brochureUrl: OmoroseBrochureFile,
        brochureDownloadText: 'Vaishnodevi Omorose',
        location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2801.55781112109!2d72.77996331404968!3d21.19230708591002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04dcaf356d181%3A0xb9d91b514b6a66dc!2sOmorose!5e1!3m2!1sen!2sin!4v1564371997754!5m2!1sen!2sin'
    },
    {
        logo: FarmvilleLogo,
        title: 'Vaishnodevi Farmville',
        backgroundMode: 'lightBlock',
        backgroundText: 'lightTextNoMargin',
        showDetails: true,
        logoAltText: 'Farmville Logo',
        projectName: 'Farmville',
        backgroundClassName: 'FarmvilleBackground',
        projectInfo: [
            "Vaishnodevi Farmville, a place to freshen up your mind. Its calm and tranquillity filled environment snatches all the stress away from you and provokes you to enjoy life.",
            "This is the place which will teach you to indulge yourself into nature. It is said that nature is the best teacher, and here at Vaishnodevi Farmville you can learn the valuable lessons of nature along with relaxing from your busy everyday life.",
			"Plunge in to purest and pristine joys in the company of enthralling nature. Your weekdays will be drenched with exhilaration and excitement and then there will be downpour of joy and joviality soaking you all the way for the rest of your life."
		],
        projectImage: FarmvilleProjectImage,
        projectDetails: [
            [
				"TOTAL NUMBER OF WEEKEND HOMES: 304",
				"LOCATION: NARTHAN GAM, DANDI ROAD"
            ],
            [
				"INDOOR GAMES: POOL TABLE, CARROM BOARD, CHESS BOARD, AIR HOCKEY & TABBLE TENNIS",
				"OUTDOOR GAMES: LAWN-TENNIS COURT, BASKETBALL COURT, CRICKET NET, SKATING AREA",
				"BANQUET HALL FOR SOCIAL FUNCTIONS",
				"GYM, SAUNA, & STEAM.",
				"DISCO THEQUE",
				"HOME THEATRE",
				"THE CENTRAL GARDEN IS A HUGE LUSH GREEN PATCH",
				"SWIMMING POOL",
				"LANDSCAPED GARDEN WITH GAZEBOS",
				"CHILDREN PLAY AREA",
				"A BEAUTIFUL LAKE WITH WATER FOUNTAINS"
            ]
        ],
        projectDetailsTitle: [
            'Overview', 'Amenities'
        ],
        layoutExists: true,
        layoutUrl: [
            FarmvilleLayout1,
             FarmvilleLayout2, FarmvilleLayout3, FarmvilleLayout4, FarmvilleLayout5
        ],
        projectSummary : [
            'Weekend Homes', 'Completed', 'NARTHAN, Dandi road'
        ],
        brochure: 'FarmvilleBrochure',
        brochureUrl: FarmvilleBrochureFile,
        brochureDownloadText: 'Vaishnodevi Farmville',
        location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.3791874347075!2d72.70615021405143!3d21.269750785868336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be1b4d2c25e4ddd%3A0xd1048c244cf22180!2sVaishnodevi+Farmville!5e1!3m2!1sen!2sin!4v1564375789087!5m2!1sen!2sin'
    },
    {
        logo: LifestyleLogo,
        title: 'Vaishnodevi Lifestyle',
        backgroundMode: 'lightBlock',
        backgroundText: 'lightTextNoMargin',
        logoAltText: 'Lifestyle Logo',
        showDetails: true,
        projectName: 'Lifestyle',
        backgroundClassName: 'LifestyleBackground',
        projectInfo: [
            "The high-end of luxury beckons you to its grand, elegant and charismatic looks. The captivating location of the project promises to be a great investment for your home. The lush green plants ensure the environment of the society remains peaceful.",
			"Vaishnodevi lifestyle has six buildings in total, with each building having 12 floors. Floors from 1 to 10th have 3 flats in each building. The 11th floor of every building consists of three penthouse.",
			"Find out more in the E-Brochure below."
		],
        projectImage: LifestyleProjectImage,
        projectDetails: [
            [
				"TOTAL NUMBER OF BUILDINGS: 6",
				"NUMBER OF FLOORS: BASEMENT + GROUND FLOOR + 12 FLOORS",
				"TYPES OF APARTMENTS: 3 & 4 BHK LIFESTYLE APARTMENTS",
				"NUMBER OF FLATS PER FLOOR: 3",
				"LOCATION: PAL, ADAJAN, SURAT"
            ],
            [
				"INDOOR GAMES: POOL, TABBLE TENNIS, CHESS, CARROM BOARD",
				"CHILDREN PARK",
				"GAZEBO",
				"SKATING RINK",
				"GYM",
				"INDOOR SWIMMING POOL",
				"HOME THEATRE",
				"LIBRARY"
            ]
        ],
        projectDetailsTitle: [
            'Overview', 'Amenities'
        ],
        layoutExists: true,
        layoutUrl: [
            LifestyleLayout1,
             LifestyleLayout2, LifestyleLayout3, LifestyleLayout4, LifestyleLayout5, LifestyleLayout6, LifestyleLayout7
        ],
        projectSummary : [
            'Residential', 'Completed', 'ADAJAN, Surat'
        ],
        brochure: 'LifestyleBrochure',
        brochureUrl: LifestyleBrochureFile,
        brochureDownloadText: 'Vaishnodevi Lifestyle',
        location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.6293384021665!2d72.77829811404955!3d21.186090185913333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04db62ba42fd7%3A0x65bf23a0ddc314e!2sVaishnodevi+Lifestyle!5e1!3m2!1sen!2sin!4v1564380300227!5m2!1sen!2sin"
    },
    {
        logo: HeightsLogo,
        title: 'Vaishnodevi Heights',
        backgroundMode: 'lightBlock',
        showDetails: true,
        backgroundText: 'lightTextNoMargin',
        logoAltText: 'Heights Logo',
        projectName: 'Heights',
        backgroundClassName: 'HeightsBackground',
        projectInfo: [
            "Vaishnodevi Heights symbolizes powerful structure, convenient location, productivity enhancing amenities, and much more",
			"It was designed and built in a way that would meet the specific needs of all people. It includes most of the amenities that are required to create a productivity generating environment.",
			"Find out more in the E-Brochure below."
		],
        projectImage: HeightsProjectImage,
        projectDetails: [
            [
				"TOTAL NUMBER OF BUILDINGS: 6",
				"NUMBER OF FLOORS: BASEMENT + GROUND FLOOR + 12 FLOORS",
				"TYPES OF APARTMENTS: 2 & 3 BHK LIFESTYLE APARTMENTS",
				"NUMBER OF FLATS PER FLOOR: 4",
				"LOCATION: JAHANGIRPURA, SURAT"
            ]
        ],
        projectDetailsTitle: [
            'Overview'
        ],
        layoutExists: true,
        layoutUrl: [
            HeightsLayout1,
             HeightsLayout2, HeightsLayout3, HeightsLayout4
        ],
        projectSummary : [
            'Residential', 'Completed', 'JAHANGIRPURA, Surat'
        ],
        brochure: 'HeightsBrochure',
        brochureUrl: HeightsBrochureFile,
        brochureDownloadText: 'Vaishnodevi Heights',
        location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1690.6718874416053!2d72.777314851111!3d21.236670426870205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04c74fa983815%3A0x6e7161c7a8180231!2sVaishnodevi+Heights%2C+Vaishnodevi+Road%2C+Jahangirpura%2C%2C+Vaishnodevi+Rd%2C+Dahin+Nagar%2C+Surat%2C+Gujarat+395005!5e1!3m2!1sen!2sin!4v1564377333730!5m2!1sen!2sin"
    },
    {
        logo: SkyLogo,
        title: 'Vaishnodevi Sky',
        backgroundMode: 'lightBlock',
        showDetails: true,
        backgroundText: 'lightTextNoMargin',
        logoAltText: 'Sky Logo',
        projectName: 'Sky',
        backgroundClassName: 'SkyBackground',
        projectInfo: [
            "Completed in the year 2012, Vaishnodevi sky helps you keep your dream of reaching the sky alive. All of the 12 buildings are constructed in such a way that it helps frame an environment suitable for forming an ideal society.",
			"There are many shops in the society, which were built to reduce your stress of going a kilo-meter away to buy mundane items.",
			"Find out more in the E-Brochure below."
		],
        projectImage: SkyProjectImage,
        projectDetails: [
            [
				"TOTAL NUMBER OF BUILDINGS: 12",
				"NUMBER OF FLOORS: BASEMENT + GROUND FLOOR + 12 FLOORS",
				"TYPES OF APARTMENTS: 2 & 3 BHK LIFESTYLE APARTMENTS",
				"NUMBER OF FLATS PER FLOOR: 4",
				"LOCATION: JAHANGIRPURA, SURAT"
            ]
        ],
        projectDetailsTitle: [
            'Overview'
        ],
        layoutExists: true,
        layoutUrl: [
            SkyLayout1,
             SkyLayout2, SkyLayout3
        ],
        projectSummary : [
            'Residential', 'Completed', 'JAHANGIRPURA, Surat'
        ],
        brochure: 'SkyBrochure',
        brochureUrl: SkyBrochureFile,
        brochureDownloadText: 'Vaishnodevi Sky',
        location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.0883338915914!2d72.77285018194922!3d21.242515004424195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04b3e9c08bfc7%3A0x85f5e60dacf6c287!2sVaishnodevi+Sky!5e1!3m2!1sen!2sin!4v1564381238276!5m2!1sen!2sin"
    },
    {
        logo: ShoppingLogo,
        title: '99 Shopping - By Vaishnodevi Group',
        backgroundMode: 'lightBlock',
        backgroundText: 'lightTextNoMargin',
        logoAltText: '99Shopping Logo',
        projectName: '99Shopping',
        layoutUrl: [],
        projectDetails: [],
        projectDetailsTitle: [],
        layoutExists: false,
        showDetails: false,
        backgroundClassName: 'ShoppingBackground',
        projectInfo: [
            "99 Shopping Whether you are planning to start a new business or expanding the old one, 99 shopping provides you a head start to survive all that competition in the world, by providing you shops at an extremely affordable rate as well as an attractive location for customers.",
			"The planning of the building ensures no shop is left unnoticeable.",
			"Find out more in the E-Brochure below."

		],
        projectImage: ShoppingProjectImage,
        projectSummary : [
            'Commercial', 'Completed', 'Amroli, Surat'
        ],
        brochure: '99ShoppingBrochure',
        brochureUrl: ShoppingBrochureFile,
        brochureDownloadText: 'Vaishnodevi 99Shopping',
        location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2804.454095671861!2d72.84979461405077!3d21.24224268588316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f29fec8d0ab%3A0x4f36f0a065b6c6b2!2s99+Shopping+mall!5e1!3m2!1sen!2sin!4v1564381814155!5m2!1sen!2sin"
    },
    {
        logo: TownshipLogo,
        title: 'Vaishnodevi Township',
        backgroundMode: 'lightBlock',
        backgroundText: 'lightTextNoMargin',
        logoAltText: 'Township Logo',
        projectDetails: [],
        projectDetailsTitle: [],
        layoutExists: false,
        showDetails: false,
        layoutUrl: [],
        projectName: 'Township',
        backgroundClassName: 'TownshipBackground',
        projectInfo: [
            "Looking for an apartment to have your home in? Let me assure you, you have come to the right place.",
			"Providing you with a society that includes all the amenities that you could find in other big projects. Don't forget to look at the beautiful garden from you balcony once you buy your home here.",
			"Find out more in the E-Brochure below."

		],
        projectImage: TownshipProjectImage,
        projectSummary : [
            'Residential', 'Completed', 'Vanakla, Surat'
        ],
        brochure: 'TownshipBrochure',
        brochureUrl: TownshipBrochureFile,
        brochureDownloadText: 'Vaishnodevi Township',
        location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.774661961756!2d72.77329631405077!3d21.240285285884127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04b8a05c5cfa7%3A0xdaa7c97f473223e4!2sVaishnodevi+Township!5e1!3m2!1sen!2sin!4v1564382483842!5m2!1sen!2sin"
    },
    {
        logo: IdealhomesLogo,
        title: "Vaishnodevi Idealhomes",
        backgroundMode: 'lightBlock',
        layoutExists: false,
        layoutUrl: [],
        projectDetails: [],
        projectDetailsTitle: [],
        backgroundText: 'lightTextNoMargin',
        logoAltText: 'Idealhomes Logo',
        projectName: 'Idealhomes',
        backgroundClassName: 'IdealhomesBackground',
        projectInfo: [
            "Ideal for children, Ideal for adults, Ideal for the elderly : just as the name suggests \"Vaishnodevi ideal homes.\" The peacefulness provides an ideal environment for children to concentrate on their studies. The jogging track helps the adults and the elderly to boost their health. ​",
			"It doesn't end there. You can find all the amenities that you wish over here.",
			"Find out more in the E-Brochure below."

        ],
        showDetails: false,
        projectImage: IdealhomesProjectImage,
        projectSummary : [
            'Residential', 'Completed', 'Vanakla, Surat'
        ],
        brochure: 'IdealhomesBrochure',
        brochureUrl: IdealhomesBrochureFile,
        brochureDownloadText: 'Vaishnodevi Idealhomes',
        location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2803.9156661171605!2d72.77309111405066!3d21.240630885883977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04b8a03c9973f%3A0x1fab4b4ec55b0521!2sVaishnodevi+Ideal+Homes!5e1!3m2!1sen!2sin!4v1564382898581!5m2!1sen!2sin"
    },
    {
        logo: KingroseLogo,
        title: 'Kingrose',
        backgroundMode: 'lightBlock',
        backgroundText: 'lightTextNoMargin',
        showDetails: true,
        logoAltText: 'Kingrose Logo',
        projectName: 'Kingrose',
        backgroundClassName: 'kingroseBackground',
        projectInfo: [
            "The goal of this project was to offer you an opportunity to build a home of your convenience at the most appropriate price for you. Its mindfully chosen location added to the long-list of reasons to buy atleast one plots at Kingrose Villa.",
            'Guj Rera Reg. number :- PR/GJ/SURAT/SURAT CITY/SUDA/PAA06903/160320',
            'Guj Rera Auth. Website:- www.gujrera.gujarat.gov.in',
        ],
        imageDisclaimer: 'Images of houses are for reference purpose only.',
        projectImage: KingroseProjectImage,
        projectDetails: [
            [
                'SEWAGE SYSTEM: R.C.C pipe of IS1 is place underground.',
                'ROAD: Long lasting and strong R.C.C roads with V.D.S (tri-mix rough finish) & sides finished with good quality pavers.',
                'COP Open C.O.P. with landscaping. Gazebo, jogging track, children play area.',
                'COMPUND WALL: Compound wall on all four sides. Front side entrance gate with Security cabin and decorative compound wall.',
                'STREET LIGHT: Street-light is provided on internal roads.',
                'BORE-WELL LOCATION: One bore-well point provision in C.O.P. CCTV camera at Drive way & common plot'
            ]
        ],
        projectDetailsTitle: [
            'Specifications'
        ],
        layoutExists: false,
        layoutUrl: [
            KingroseLayout1,
             KingroseLayout2, KingroseLayout3, KingroseLayout4
        ],
        projectSummary : [
            'Plotted Development', 'Completed', 'Dandi Road, Jahangirpura, Surat'
        ],
        brochure: 'kingroseBrochure',
        brochureUrl: KingroseBrochureFile,
        brochureDownloadText: 'Kingrose Villa 1',
        location: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14876.380082781629!2d72.7774387!3d21.2280803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8b731018724a22a4!2sKINGROSE!5e0!3m2!1sen!2sin!4v1622017896681!5m2!1sen!2sin'
    },
    {
        logo: Kingrose2Logo,
        title: 'Kingrose Villa 2',
        backgroundMode: 'lightBlock',
        backgroundText: 'lightTextNoMargin',
        showDetails: true,
        logoAltText: 'Kingrose Logo',
        projectName: 'Kingrose',
        backgroundClassName: 'kingroseBackground',
        projectInfo: [
            "All of the more than 50 plots of Kingrose Villa 1 were sold out in a blink of an eye. After seeing the enormous response for it, we decided to come up with, Kingrose Villa 2, another opportunity for you to get the best plot where you could build the home of your dreams.",
            'Guj Rera Reg. number :- ',
            'Guj Rera Auth. Website:- www.gujrera.gujarat.gov.in',
        ],
        imageDisclaimer: 'Images of houses are for reference purpose only.',
        projectImage: KingroseProjectImage,
        projectDetails: [
            [
                'SEWAGE SYSTEM: R.C.C pipe of IS1 is place underground.',
                'ROAD: Long lasting and strong R.C.C roads with V.D.S (tri-mix rough finish) & sides finished with good quality pavers.',
                'COP Open C.O.P. with landscaping. Gazebo, jogging track, children play area.',
                'COMPUND WALL: Compound wall on all four sides. Front side entrance gate with Security cabin and decorative compound wall.',
                'STREET LIGHT: Street-light is provided on internal roads.',
                'BORE-WELL LOCATION: One bore-well point provision in C.O.P. CCTV camera at Drive way & common plot',
                '29\'-6\" Wide Driveway'
            ]
        ],
        projectDetailsTitle: [
            'Specifications'
        ],
        layoutExists: true,
        layoutUrl: [
            Kingrose2Layout1,
             Kingrose2Layout2
        ],
        projectSummary : [
            'Plotted Development', 'On-Going', 'Dandi Road, Jahangirpura, Surat'
        ],
        brochure: 'Kingrose2_Brochure',
        brochureUrl: Kingrose2BrochureFile,
        brochureDownloadText: 'Kingrose Villa 2',
        location: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14876.380082781629!2d72.7774387!3d21.2280803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8b731018724a22a4!2sKINGROSE!5e0!3m2!1sen!2sin!4v1622017896681!5m2!1sen!2sin'
    },
    {
        logo: KingswoodLogo,
        title: 'Kingswood',
        backgroundMode: 'lightBlock',
        showDetails: true,
        backgroundText: 'lightTextNoMargin',
        logoAltText: 'Kingswood Logo',
        projectName: 'Kingswood',
        backgroundClassName: 'KingswoodBackground',
        projectInfo: [
            "Kingswood ~ A great place to be",
            "At Kingswood, you will be surrounded by features to make every moment a pleasure to live and enjoy life in a gated complex with state-of-the-art security.",
			"Kingswood is all about sheer luxury. You will be surprise by the amount of space Kingswood offers for 2 & 3 bhk flats at a price that is a perfect match for your budget.",
            'Guj Rera Reg. Number:- PR/GJ/SURAT/SURAT CITY/SUDA/RAA08230/190321',
            'Guj Rera Auth. Website:- www.gujrera.gujarat.gov.in'
        ],
        projectImage: KingswoodProjectImage,
        projectDetails: [
            [
                    "Total number of buildings: 6",
                    "Number of floors: Basement + Ground Floor + 1st to 14th floors + Terrace Garden",
                    "Types of flats: 2 & 3 bhk luxurious flats.",
                    "Number of flats per floor: 4",
                    "Location: Jahangirabad, Surat."
            ],
            [
                    "Designated Use: Residential High-rise",
                    "Ample parking space available for all residents",
                    "building structure: 1 basement + ground + 14 storey RCC framed structure as IS code with concrete block/Brick masonry walls.",
                    "CCTV camera in common area",
                    "Entrance foyer finished with granamite"
            ],
            [
                    "Multi-purpose sports court",
                    "Children play area",
                    "Centrally located party lawn with landscape garden",
                    "Garden gazebo", 
                    "Net cricket",
                    "Peripheral cycling track/Jogging track",
                    "Terrace garden with gazebo deck seating and open seating"
            ]
        ],
        projectDetailsTitle: [
            'Overview', 'Features', 'Amenities'
        ],
        layoutExists: true,
        layoutUrl: [
            KingswoodLayout1, KingswoodLayout2, KingswoodLayout3, KingswoodLayout4, KingswoodLayout5
        ],
        projectSummary : [
            'Residential', 'On-going', 'Jahangirabad'
        ],
        brochure: 'Kingswood Brochure',
        brochureUrl: KingswoodBrochureFile,
        brochureDownloadText: 'Kingswood',
        location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3859.5310691732907!2d72.77519771495783!3d21.228024086321664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04db67030ca8d%3A0xc9f38d736ead6a4d!2sKingswood!5e1!3m2!1sen!2sus!4v1656598819205!5m2!1sen!2sus'
    },
    {
        logo: ResidencyLogo,
        title: 'Vaishnodevi Residency',
        backgroundMode: 'lightBlock',
        showDetails: true,
        backgroundText: 'lightTextNoMargin',
        logoAltText: 'Vaishnodevi Residency Logo',
        projectName: 'Vaishnodevi Residency',
        backgroundClassName: 'ResidencyBackground',
        projectInfo: [
            "Vaishnodevi Residency is an affordable housing project which aims to help people buy a home of their own at the most affordable rate possible.",
            "Along with being extremely affordable, we try to provide amenities and features that could enhance the lifestyle of people who will live here.",
            "Guj Rera Number:- PR/GJ/SURAT/SURAT CITY/SUDA/MAA08141/030321",
            'Guj Rera Auth. Website:- www.gujrera.gujarat.gov.in'
        ],
        projectImage: ResidencyProjectImage,
        projectDetails: [
            [
                    "Total number of buildings: 8",
                    "Number of floors: 7",
                    "Types of flats: 2 bhk flats",
                    "Number of flats per floor: 4",
                    "Location: Kosad, Amroli, Surat."
            ],
            [
                    "Central garden with gazebo seating",
                    "Children play area"
            ]
        ],
        projectDetailsTitle: [
            'Overview', 'Amenities'
        ],
        layoutExists: true,
        layoutUrl: [
            ResidencyLayout1,
            ResidencyLayout2, ResidencyLayout3, ResidencyLayout4
        ],
        projectSummary : [
            'Residential', 'On-Going', 'Kosad, Amroli'
        ],
        brochure: 'VaishnodeviResidency',
        brochureUrl: VaishnodeviResidencyBrochure,
        brochureDownloadText: 'Vaishnodevi Residency',
        location: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1182.5802328150226!2d72.8585345206533!3d21.245276399215168!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2b5999ba1d8de796!2sVaishno%20devi%20residency!5e1!3m2!1sen!2sin!4v1622018117500!5m2!1sen!2sin'
    }
]