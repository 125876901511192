import React, { useEffect } from "react"; // Get useEffect
import { withLazyImageContext } from "./lazyImageContext"; // Grab the context connector
import "./lazyImage.css"; // Grab css

const LazyImage = ({ src, aspectRatio, lazyLoad, addId, addClass, mediumSrc, mobileSrc }) => {

  // Calculate the aspect ratio
  let paddingTop = 0;
  if(aspectRatio){
     paddingTop = `${(aspectRatio[1] / aspectRatio[0]) * 100}%`;
  }

  let isAdded = '';
  if(addId) {
    isAdded = addId;
  }

  let classAdded = "lazyImage__img";
  if(addClass) {
    classAdded = classAdded.concat(" ")
    classAdded = classAdded.concat(addClass)
  }

  useEffect(() => {
    if (lazyLoad) lazyLoad.update();
  }, [src, aspectRatio, lazyLoad, addId]);
  
  // Set the placeholder size on our wrapper div
  return (
    <div className="lazyImage"  style={{ paddingTop }}>
    <picture>
      <source media="(min-width:650px)" data-srcset={src}/>
      <source media="(min-width:465px)" data-srcset={mediumSrc}/>
      <img id={isAdded} className={classAdded} data-src={mobileSrc}/>
    </picture>
    </div>
  );
};

// Connect this component so we get access to the LazyLoad API
export default withLazyImageContext(LazyImage);