import React from 'react';
import {Container, Col, Row} from 'react-bootstrap'
import '../styling/home.css'
import Email from '../images/email.svg'
import Location from '../images/location.svg'
import Phone from '../images/phone.svg'
import Facebook from '../images/facebook.svg'
import Instagram from '../images/instagram.svg'
import Youtube from '../images/youtube.svg'

function Footer() {

  return (
    <div className="Footer w-100">
        <div className="footer">
        <Container fluid>
            <Row className="w-100 align-items-center">
                <Col md={6} className="text-left">
                    <h4>Contact Info</h4>
                    <div id="contact-info">
                        <p><span><img className="vectorIcons" src={Location} alt="Icon"></img></span> Kingswood, Behind D-Mart, Beside Canal Road, Jahangirabad, Surat</p>
                        <p><span><img className="vectorIcons" src={Phone} alt="Icon"></img></span> +91 98987 91838</p>
                        <p><span><img className="vectorIcons" src={Email} alt="Icon"></img></span> <a
                            href={"mailto:vaishnodevirealty@yahoo.com?Subject=Schedule"} target="_top">vaishnodevirealty@yahoo.com</a>
                        </p>
                        <p>
                            <a href="https://www.facebook.com/Vaishnodevi-Realty-PVT-LTD-1597907703830918"><span className="pr-3"><img className="vectorIcons" src={Facebook} alt="Icon"></img></span></a>
                            <a href="https://www.instagram.com/vaishnodevirealty/?hl=en"><span className="pr-3"><img className="vectorIcons" src={Instagram} alt="Icon"></img></span></a>
                            <a href="https://www.youtube.com/channel/UC8UTQHmwbLVxmlXYapFEM5A"><span className="pr-3"><img className="vectorIcons" src={Youtube} alt="Icon"></img></span></a>
                        </p>
                    </div>                    
                </Col>
            </Row>
        
            <div className="w-100">
                <Col md={12} className="text-center">
                    <p className="resolveIssues">Contact vaishnodevirealty.resolveissues@gmail.com for accessibility issues.</p>
                </Col>
            </div>
        </Container>
        </div>
    </div>
  );
}

export default Footer;
