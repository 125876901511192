import React from 'react';
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload'
import '../styling/home.css'

function ProjectCard(props) {
  return (
    <div className="projectCard">
        <Card className="mt-5 lightBackground" style={{ width: '100%'}}>
          <Link to={props.projectPath}>
            <LazyLoad height={300} offset={50}>
              <img style={{height: '293px'}} className="w-100" src={props.projectImage}></img>
            </LazyLoad>
            <Card.Body className="lightText">
              <Card.Title>{props.projectTitle}</Card.Title>
              <Card.Text>
                {props.projectText}
              </Card.Text>
            </Card.Body>
          </Link>
        </Card>
      </div>
  );
}

export default ProjectCard;
