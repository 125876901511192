import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {GalleryPageData} from './galleryData'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import KingswoodImage1 from '../images/Kingswood/carousel1.jpg'
import KingswoodImage2 from '../images/Kingswood/carousel2.jpg'
import KingswoodImage3 from '../images/Kingswood/carousel3.jpg'
import KingswoodImage4 from '../images/Kingswood/carousel4.jpg'
import KingswoodImage5 from '../images/Kingswood/carousel5.jpg'
import KingswoodImage6 from '../images/Kingswood/carousel6.jpg'
import KingswoodImage7 from '../images/Kingswood/kingswood_entrance.jpg'
import KingswoodImage8 from '../images/Kingswood/View_001.jpg'
import KingswoodImage9 from '../images/Kingswood/view_01_new.jpg'
import KingswoodImage10 from '../images/Kingswood/View_002.jpg'
import KingswoodImage11 from '../images/Kingswood/view_04_new.jpg'
import KingswoodImage12 from '../images/Kingswood/viiew_05_Budhha_New.jpg'

const images = [
    {original: KingswoodImage1, thumbnail: KingswoodImage1, originalClass: 'galleryImage'},
    {original: KingswoodImage2, thumbnail: KingswoodImage2, originalClass: 'galleryImage'},
    {original: KingswoodImage3, thumbnail: KingswoodImage3, originalClass: 'galleryImage'},
    {original: KingswoodImage4, thumbnail: KingswoodImage4, originalClass: 'galleryImage'},
    {original: KingswoodImage5, thumbnail: KingswoodImage5, originalClass: 'galleryImage'},
    {original: KingswoodImage6, thumbnail: KingswoodImage6, originalClass: 'galleryImage'},
    {original: KingswoodImage7, thumbnail: KingswoodImage7, originalClass: 'galleryImage'},
    {original: KingswoodImage8, thumbnail: KingswoodImage8, originalClass: 'galleryImage'},
    {original: KingswoodImage9, thumbnail: KingswoodImage9, originalClass: 'galleryImage'},
    {original: KingswoodImage10, thumbnail: KingswoodImage10, originalClass: 'galleryImage'},
    {original: KingswoodImage11, thumbnail: KingswoodImage11, originalClass: 'galleryImage'},
    {original: KingswoodImage12, thumbnail: KingswoodImage12, originalClass: 'galleryImage'},
]

function Gallery() {

    return(
        <div className="gallery mt-5">
            <Container fluid className="darkBlock pt-5 mt-5">
            <Container className="pt-5">
                <Row className="darkTextNoMargin w-100 pt-5 pb-5 justify-content-center">
                    <h3 className="text-center pt-5">Kingswood</h3>
                </Row>
                <Row className="darkTextNoMargin w-100">
                {/*
                
                 <LazyLoad height={450}>
                        <Col md={12}>
                            <ImageGallery thumbnailPosition="left" lazyLoad={true} items={images} />
                        </Col>
                    </LazyLoad>
                 -*/}
                        <Col md={12}>
                            <ImageGallery showFullscreenButton={false} lazyLoad={true} thumbnailPosition="left" lazyLoad={true} items={images} />
                        </Col>
                </Row>
            </Container>
            {
                GalleryPageData.map((project) => (
                    <Container className="pt-5">
                        <Row className="darkTextNoMargin w-100 pt-5 pb-5 justify-content-center">
                            <h3 className="text-center">{project.projectTitle}</h3>
                        </Row>
                        <Row className="darkTextNoMargin w-100">
                          {/* Commented to stop size of image affecting arrow position  <LazyLoad height={450}>*/}
                                <Col style={{height: '450px'}} md={12}>
                                    <ImageGallery showFullscreenButton={false} thumbnailPosition="left" lazyLoad={true} items={project.images} />
                                </Col>
                           {/* </LazyLoad> */}
                        </Row>
                    </Container>
                ))
            }
            </Container>
        </div>
    )
}

export default Gallery;