import React from 'react';
import '../styling/home.css'
import {Container, Row, Col} from 'react-bootstrap'
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal/Fade'
import Ripples from 'react-ripples'
import OmoroseCard from '../images/Omorose/15.jpg'
import { useHistory } from 'react-router-dom';
import {Helmet} from 'react-helmet'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-135620216-1');
ReactGA.pageview(window.location.pathname + window.location.search);


function Awards() {

  const history = useHistory();

  const delayRouteChange = () => {
    setTimeout(routeChange, 1500);
  }
  
  const routeChange = () => {
    history.push("/omorose");
  }

  return (
    <div className="awards pt-5">
    <Helmet>
      <meta description="Luxurious flats at best price in Surat, Gujarat." />
      <title>Awards</title>
    </Helmet>
    <Container fluid className="pt-5 pb-5 mt-5">
      <Row className="justify-content-center w-100 ml-0">
        <Col md={12}>
          <h3 className="lightText text-center text-uppercase">
            Award for the best residential project under the ultra-luxury segment in surat
          </h3>
        </Col>
      </Row>
      <Row style={{overflow:'hidden'}} className="justify-content-around lightTextNoMargin w-100 pt-3 pb-5 ml-auto mr-auto">
        <Col className="mt-auto mb-auto" md={5}>
          <Fade left>
              <LazyLoad style={{overflow: 'hidden'}} height={300}>
                <img className="smallImage p-3 border_image h-100" src={OmoroseCard}></img>
              </LazyLoad>
          </Fade>
        </Col>
        <Col md={5} className="mt-auto mb-auto text-justify">
          <Fade right big>
            <p>In 2019, our project <span class="text-uppercase" style={{fontWeight: 'bold'}}>Omorose</span> received the award, presented by CNBC-Bajar, for the best ultra-luxurious
                project in
                Surat.</p>
          </Fade>
          <Fade right big>
            <p>The project has 46 exclusive ultra-luxurious flats and we had received a grand response in its
                booking phase. As of now, the project is completed and the possession has been handed over to the
                society.</p>
          </Fade>
          <Fade right big>
            <p>The name Omorose itself suggests the grandeur of it. The name Omorose is said to be of African origin
                and means "Beautiful" in Egyptian.</p>
          </Fade>
          <Fade right big>
            <p>The motivation behind this project remained to give our best in building a beautiful and harmonious
                society where people could live jovially for years.</p>
          </Fade>
            <Ripples color="#f2f2f2" during={1200}>
              <button style={{width: '80px', borderRadius: '20px'}} className="btn btn-primary explore_more" onClick={delayRouteChange}>Visit</button>
            </Ripples>
        </Col>
      </Row>
    </Container>
    </div>
  );
}

export default Awards;
