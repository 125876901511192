import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from 'react-router-dom'
import '../styling/header.css'
import '../styling/home.css'
import groupLogo from '../images/logo/Vaishnodevi_Group.png';
import companyLogo from '../images/logo/Vaishnodevi_realty.png';
import groupLogoMobile from '../images/logo/Vaishnodevi_Group.png';
import companyLogoMobile from '../images/logo/Vaishnodevi_realty.png';



let prevScrolldirection = window.pageYOffset;
window.onscroll = () => {
    let currentScrolldirection = window.pageYOffset;
    if(prevScrolldirection > currentScrolldirection) {
        document.getElementById("navbar").style.top = "0";
    }
    else if(currentScrolldirection < 100) {
        document.getElementById("navbar").style.top = "0";
    }
    else {
        document.getElementById("navbar").style.top = "-300px";
    }
    prevScrolldirection = currentScrolldirection
}


function Header(props) {

    const {location} = props;


  return (
    <div className="Header d-flex justify-content-center">
        <Navbar id="navbar" style={{position: 'fixed', zIndex: 20, width: '100%', transition: 'top 0.7s', backgroundColor: '#f2f2f2', top: '0px'}} 
        expand="md">
            <Navbar.Brand href="/">
                <Link to="/">
                    <picture>
                        <source media="(min-width:500px)" data-srcset={companyLogo}/>
                        <img id="vrpl_logo"  src={companyLogoMobile} alt="Vaishnodevi Realty Logo"/>
                    </picture>
                </Link>
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav activeKey={location.pathname} className="mr-auto ml-auto">
                <Link title="Home" to="/" className="tabsColor">Home</Link>
                <Link title="About" to="/about" className="tabsColor">About</Link>
                <Link title="Ongoing" to="/on_going" className="tabsColor">Ongoing</Link>
                <Link title="Completed" to="/completed" className="tabsColor">Completed</Link>
                <Link title="Awards" to="/awards" className="tabsColor">Awards</Link>
                <Link title="Gallery" to="/gallery" className="tabsColor">Gallery</Link>
                </Nav>
            </Navbar.Collapse>
            <Navbar.Brand href="/about">
                <Link to="/about">
                    <picture>
                        <source media="(min-width:500px)" data-srcset={groupLogo}/>
                        <img className="general_group_logo" src={groupLogoMobile} alt="Vaishnodevi Group Logo"/>
                    </picture>
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </Navbar>
    </div>
  );
}

export default Header;
