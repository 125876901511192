import React from 'react'
import {Card, Container, Row, Col} from 'react-bootstrap'
import {Accordion, Carousel} from 'react-bootstrap'
import LazyLoad from 'react-lazyload'
import {ProjectPageData} from './projectData'
import Fade from 'react-reveal/Fade'
import Ripples from 'react-ripples'
import { Link } from 'react-router-dom';
import ModalImage from 'react-modal-image'
import Kingswood from '../images/Kingswood/View_002.jpg'
import Building from '../images/building.svg'
import Location from '../images/location.svg'
import Status from '../images/status.svg'
import {Helmet} from 'react-helmet'
import ReactGA from 'react-ga'


function Project(props) {

    let projectRetrieved = ProjectPageData[props.projectIndex]

    ReactGA.initialize('UA-135620216-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    let backgroundImageClass = projectRetrieved.backgroundClassName;
    backgroundImageClass += ' mt-5';

    let hideLayout = true
    if(projectRetrieved.layoutExists) {
        hideLayout = false
    }

    let hideDetails = true
    if(projectRetrieved.showDetails) {
        hideDetails = false
    }

    return(
    <div style={{backgroundColor: '#f2f2f2'}} className="project">
        <Helmet>
            <meta description={projectRetrieved.brochureDownloadText} />
            <title>{projectRetrieved.title}</title>
        </Helmet>
        <div className={backgroundImageClass}></div>
        <Container fluid className="lightBlock">
            <Row className="justify-content-around text-justify align-items-center lightTextNoMargin w-100 ml-auto mr-auto pb-5">
                <Col md={6}>
                    <Row className="w-100">
                        <Col md={12}>
                            <img className="logo" src={projectRetrieved.logo} alt={projectRetrieved.logoAltText} />     
                        </Col>
                    </Row>
                    <Row className="w-100">
                        <Col md={12}>
                            {
                                projectRetrieved.projectInfo.map((info) => (
                                    <Fade bottom>
                                        <p className="w-100">{info}</p>
                                    </Fade>
                                ))
                            }
                            <p style={{opacity: '50%', fontSize: '60%'}}>{projectRetrieved.imageDisclaimer}</p>
                            <p style={{opacity: '50%', fontSize: '60%'}}>{projectRetrieved.funding}</p>
                            <Ripples color="#00254d" during={1200}>
                                <a href={projectRetrieved.brochureUrl}
                                download={projectRetrieved.brochureDownloadText}>
                                <button className="btn btn-primary explore_moreLight mt-2">
                                Download E-Brochure
                                </button>
                                </a>                           
                            </Ripples>
                        </Col>
                    </Row>  
                </Col>
                <Col md={4} className="pt-auto pb-auto">
                        <Fade top>
                            <LazyLoad height={300}>
                                <img className="smallImage mt-3" src={projectRetrieved.projectImage}/>
                            </LazyLoad>
                        </Fade>
                </Col>
            </Row>
        </Container>

        <Container fluid className="p-5 darkBlock pt-5 pb-5 w-100">
            <Row className="justify-content-center darkTextNoMargin w-100 ml-0">
                { React.createElement(Col, {className: hideLayout ? 'hide' : 'py-2 px-2 m-auto', md: 4 } , 
                    <div>
                        <h5 className="darkTextNoMargin text-center">Layouts</h5>
                        <Carousel>
                            {
                                projectRetrieved.layoutUrl.map((image, index) => (
                                    <Carousel.Item className="h-100">
                                        <ModalImage className="layoutCarousel smallCarousel" small={image} large={image}></ModalImage>
                                    </Carousel.Item>
                                ))
                            }
                        </Carousel>
                    </div>
                
                ) }
                <Col md={4} className="text-center p-2 m-auto">
                    <div className="pt-5 pb-5 text-uppercase">
                        <Fade bottom>
                            <h3 className="text-center">Project Summary</h3>
                        </Fade>
                        <Fade bottom>
                            <h6 className="p-2 text-unselected"><span><img className="vectorIcons" src={Building} alt="Icon"/></span>
                                Project Type: {projectRetrieved.projectSummary[0]}</h6>
                        </Fade>
                        <Fade bottom>
                            <h6 className="p-2 text-unselected"><span><img className="vectorIcons" src={Status} alt="Icon"/></span>
                                Project Status: {projectRetrieved.projectSummary[1]}</h6>
                        </Fade>
                        <Fade bottom>
                            <h6 className="p-2 text-unselected"><span><img className="vectorIcons" src={Location} alt="Icon"/></span> Area: {projectRetrieved.projectSummary[2]}</h6>
                        </Fade>
                    </div>
                </Col>
            </Row>
        </Container>

        { React.createElement("div", {className: hideDetails ? 'hide' : 'container-fluid lightBlock pt-5 lightBlock w-100' } ,
                <Row className="justify-content-around align-items-center darkTextNoMargin w-100 ml-auto mr-auto pb-5">
                    <Col md={10} className="pt-5">
                        <Accordion defaultActiveKey="0">
                            {
                                projectRetrieved.projectDetailsTitle.map((theTitle, index) => (
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                                            <p className="text-uppercase text-center lightTextNoMargin">{theTitle}</p>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={index.toString()}>
                                            <Card.Body>
                                                <ul>
                                                {
                                                    projectRetrieved.projectDetails[index].map(
                                                        (crntItem) => (
                                                            <li className="lightTextNoMargin text-left p-2">{crntItem}</li>
                                                        )
                                                    )
                                                }
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))
                            }
                        </Accordion> 
                    </Col>
                </Row>
            ) 
            }

            <Container fluid className="lightBlock">
                <Row style={{overflow: 'hidden'}} className="justify-content-center pb-5 w-100 ml-auto mr-auto">
                    <Col md={10} className="map mx-0">
                        <LazyLoad height={450}>
                            <iframe title="location"
                                src={projectRetrieved.location}
                                width="100%" height="450px" frameborder="0" style={{border: '2px solid #0154ab'}}
                                allowfullscreen></iframe>
                        </LazyLoad>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="lightBlock justify-content-between pb-5">
                <Row className="w-100 ml-0">
                    <Col md={12} className="lightTextNoMargin text-center pr-0">
                        <Fade top>
                            <h4>Featured Projects</h4>
                        </Fade>
                    </Col>
                </Row>
                <Row className="w-100 ml-0">
                    <Col md={12} className="pr-0 pl-0">
                        <Row className="w-100 ml-auto mr-auto pb-5">
                           <Col md={true}>
                                <Fade big>
                                <Card className="mt-5 lightBackground" style={{ width: '100%'}}>
                                <Link to="/kingsley">
                                    <LazyLoad height={293}>
                                        <img src={ProjectPageData[0].projectImage} className="w-100"></img>
                                    </LazyLoad>
                                    <Card.Body className="lightText">
                                    <Card.Title>Kingsley</Card.Title>
                                    <Card.Text>
                                        Come check out the newly built sample flats. You will be amazed by its luxuriousness.
                                    </Card.Text>
                                    </Card.Body>
                                </Link>
                                </Card>
                                </Fade>
                                </Col>
                            <Col md={true} className="mt-5">
                                <Fade big>
                                <Card className="mt-5 lightBackground" style={{ width: '100%' }}>
                                <Link to="/kingrose2">
                                    <LazyLoad height={293}>
                                        <img src={ProjectPageData[10].projectImage} className="w-100"></img>
                                    </LazyLoad>
                                    <Card.Body className="lightText">
                                    <Card.Title>Kingrose Villa 2</Card.Title>
                                    <Card.Text>
                                        Incase you missed the opportunity of buying a plot at Kingrose Villa 1, here is a second chance for you.
                                    </Card.Text>
                                    </Card.Body>
                                </Link>
                                </Card>
                                </Fade>
                            </Col>
                            <Col md={true}>
                            <Fade big>
                                <Card className="mt-5 lightBackground" style={{ width: '100%' }}>
                                <Link to="/kingswood">
                                    <LazyLoad height={293}>
                                        <img src={Kingswood} className="w-100"></img>
                                    </LazyLoad>
                                    <Card.Body className="lightText">
                                    <Card.Title>Kingswood</Card.Title>
                                    <Card.Text>
                                        Have you been looking for luxurious 2 & 3 bhk flats? Well, Kingswood seems to be the right fit for you.
                                    </Card.Text>
                                    </Card.Body>
                                </Link>
                                </Card>
                                </Fade>
                                </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

    </div>
    )
}

export default Project;