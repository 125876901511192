import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import ScrollToTop from './components/Scroller'
import Home from './ProjectPages/home'
import About from './ProjectPages/about'
import Completed from './ProjectPages/completed'
import Ongoing from './ProjectPages/ongoing'
import Awards from './ProjectPages/awards'
import Header from './components/header'
import Footer from './components/footer'
import Project from './ProjectPages/projectRenderer'
import Gallery from './ProjectPages/galleryPage'
import { withRouter } from "react-router";

const HeaderWithRouter = withRouter(Header);

function App() {
  return (
   
    <BrowserRouter >
    <ScrollToTop/>

            <header>
              <HeaderWithRouter />
            </header>

    
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/completed" exact component={Completed} />
            <Route path="/on_going" exact component={Ongoing} />
            <Route path="/awards" exact component={Awards} />
            <Route path="/gallery" exact component={Gallery} />
            <Route path="/kingsley" exact render={(props) => <Project {...props} projectIndex={0}/>}/>
            <Route path="/kingston" exact render={(props) => <Project {...props} projectIndex={1}/>}/>
            <Route path="/omorose" exact render={(props) => <Project {...props} projectIndex={2}/>}/>
            <Route path="/farmville" exact render={(props) => <Project {...props} projectIndex={3}/>}/>
            <Route path="/lifestyle" exact render={(props) => <Project {...props} projectIndex={4}/>}/>
            <Route path="/heights" exact render={(props) => <Project {...props} projectIndex={5}/>}/>
            <Route path="/sky" exact render={(props) => <Project {...props} projectIndex={6}/>}/>
            <Route path="/99shopping" exact render={(props) => <Project {...props} projectIndex={7}/>}/>
            <Route path="/township" exact render={(props) => <Project {...props} projectIndex={8}/>}/>
            <Route path="/idealhomes" exact render={(props) => <Project {...props} projectIndex={9}/>}/>
            <Route path="/kingrose" exact render={(props) => <Project {...props} projectIndex={10}/>}/>
            <Route path="/kingrose2" exact render={(props) => <Project {...props} projectIndex={11}/>}/>
            <Route path="/kingswood" exact render={(props) => <Project {...props} projectIndex={12}/>}/>
            <Route path="/residency" exact render={(props) => <Project {...props} projectIndex={13}/>}/>

      <footer>
        <Footer />
      </footer>

    </BrowserRouter>

  );
}

export default App;
