import React from 'react';
import '../styling/home.css'
import '../styling/about.css'
import groupLogo from '../images/logo/Vaishnodevi_Group.png';
import companyLogo from '../images/logo/Vaishnodevi_realty.png';
import { Carousel, Container, Col, Row } from 'react-bootstrap'
import LazyLoad from 'react-lazyload';
import { LazyImageProvider } from "../LazyImage/lazyImageContext"; //
import LazyImage from '../LazyImage/lazyImage'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom';
import Water from '../images/Kingston/water2.jpg'
import Waterfall from '../images/Kingston/waterfall_view.jpg'
import Omorose from '../images/Omorose/05.jpg'
import Vision from '../images/Kingsley/Cam_01_Night.jpg'
import OmoroseView from '../images/Omorose/02.jpg'
import OmoroseNight from '../images/Omorose/15.jpg'
import Bird from '../images/Kingston/bird.jpg'
import {Helmet} from 'react-helmet'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-135620216-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const images = [
  Water, Waterfall, Omorose
];

function About() {
  return (

    <div className="About">
      <Helmet>
        <meta description="Top Real Estate Developers in Surat, Gujarat." />
        <title>About</title>
      </Helmet>
      <div className="aboutBackground mt-5"></div>
      <Container fluid className="darkBlock py-5">
        <Row className="justify-content-around w-100 darkTextNoMargin align-items-center ml-0">
          <Col md={true}>
            <img className="general_vrpl_logo rounded-circle" src={companyLogo} alt="Vaishnodevi Realty Logo" />
          </Col>
          <Col md={true}>
            <h3 className="text-center darkText">About the company</h3>
          </Col>
          <Col md={true}>
            <img className="general_vrpl_logo rounded-circle" src={groupLogo} alt="Vaishnodevi Realty Logo" />
          </Col>
        </Row>
        <Row className="justify-content-around w-100 darkTextNoMargin text-justify align-items-center">
        <Col md={5} className="mt-2 pt-4 pl-5">
            <Fade bottom>
              <p>Vaishnodevi Realty PVT. LTD. is a real estate development company that was established in the year
                  2013. It was established with the goal of expanding the flawless service that we provided under
                  Vaishnodevi Group.</p>
            </Fade>
            <Fade bottom>
              <p>Two magnificently luxurious projects have already been completed under this company; namely
                  Kingston (A project of 13 buildings with 308 flats) and Omorose (A project
                  of 2 buildings consisting of 46 exclusive, limited edition flats). Both of these projects are
                  located in the Pal, Adajan area of Surat.</p>
            </Fade>
            <Fade bottom>
              <p>Our ambition, as we go further into this business, remains to keep the customer satisfaction at its
                  peak. We here at Vaishnodevi Realty have decided to envision the limitless.</p>
            </Fade>
          </Col> 
          <Col md={5} className="mt-2 pt-4 pl-5">
            <Fade bottom>
              <LazyImageProvider>
                <Carousel defaultActiveIndex={0}  interval={6000} className="smallCarousel">
                  {images.map((image, index) => (
                      <Carousel.Item>
                        <LazyImage addClass="smallCarousel" aspectRatio={[4, 3]} src={image} key={index} mobileSrc={image} mediumSrc={image} />
                      </Carousel.Item>
                    ))}
                </Carousel>
              </LazyImageProvider>
            </Fade>
          </Col>
        </Row>
      </Container>
      <Container fluid className="pb-5 darkBlock">
        <Row className="justify-content-around w-100 darkTextNoMargin text-justify ml-0">
          <Col className="mt-4" md={12}>
            <h3 className="text-center darkTextNoMargin mt-0">Our Work</h3>
          </Col>
        </Row>
        <Row className="justify-content-around w-100 darkTextNoMargin align-items-center ml-0">
          <Col md={5} className="mb-2 pt-4">
            <Fade bottom>
              <LazyLoad height={300}>
                <img src={OmoroseView} className="border_image"></img>
              </LazyLoad>
            </Fade>
          </Col>
          <Col md={5} className="mb-2 pt-4">
              <Fade bottom>
                <p>We started our business in the year 2005 under the name of SR corporation, and then we later on established the Shri Hari Corporation.</p>
              </Fade>
              <Fade bottom>
                <p>After few years, in 2008, we founded the Vaishnodevi Group. As we went on creating our legacy, we established Vaishnodevi Realty in the year 2013.</p>
              </Fade>
              <Fade bottom>
                <p> We have already
                    completed several projects in Pal, Adajan, Varachha, Amroli, Jahangirpura, and Vanakla. All of these
                    projects  are extremely capacious and the location of each of the project is ideal.</p>
              </Fade>
              <Fade bottom>
                <p>Our completed projects include residential, commercial, as well as farm-house projects and all of
                    them have received a grand response on opening and completion.</p>
              </Fade>
              <Fade bottom>
                <p>As of now, there are four on-going projects: <Link style={{color: '#20853b'}} to="/kingsley">Kingsley</Link>, <Link style={{color: '#20853b'}} to="/kingswood">Kingswood</Link> , <Link style={{color: '#20853b'}} to="/kingrose2">Kingrose Villa 2</Link> , and <Link style={{color: '#20853b'}} to="/residency">Vaishnodevi Residency</Link>
                <br/>You are also
                    warmly welcomed to <Link style={{color: "#20853b"}} to="/completed">visit</Link> any of our completed projects.</p>
              </Fade>
          </Col>
        </Row>
      </Container>

      <Container fluid className="pt-5 pb-5 lightBlock">
        <Row className="w-100 justify-content-around ml-auto mr-auto pt-3 pb-3">
            <Col md={true} className="pt-3">
                <Row className="row justify-content-center w-100 ml-0">
                    <Col md={8}>
                      <LazyLoad height={300}>
                        <img className="circled_image rounded-circle h-100" src={Vision}></img>
                      </LazyLoad>
                    </Col>
                </Row>
                <Row className="pt-3 w-100 ml-0">
                    <Col md={12} className="text-center pt-4">
                        <h4 className="lightTextNoMargin">Our Vision</h4>
                    </Col>
                </Row>
                <Row className="justify-content-center pt-2 w-100 ml-0">
                    <Col md={8} className="text-center">
                        <p  className="lightTextNoMargin">Build the most luxurious societies and projects, with productivity enhancing environment, at
                            the most affordable rates.</p>
                    </Col>
                </Row>
            </Col>
            <Col md={true} className="pt-3 op">
                <Row className="justify-content-center w-100 ml-0">
                    <Col md={8}>
                      <LazyLoad height={300}>
                        <img className="circled_image rounded-circle h-100" src={OmoroseNight}></img>
                      </LazyLoad>
                    </Col>
                </Row>
                <Row className="pt-3 w-100 ml-0">
                    <Col md={12} className="text-center">
                        <h4 className="lightTextNoMargin pt-4">Our Mission</h4>
                    </Col>
                </Row>
                <Row className="justify-content-center pt-2 w-100 ml-0">
                    <Col md={8} className="col-md-8 text-center">
                        <p className="lightTextNoMargin">Provide exceptional customer service and experience. Emphasizing and ensuring that the
                            projects are of excellent quality.</p>
                    </Col>
                </Row>
            </Col>
            <Col md={true} className="pt-3 op">
                <Row className="justify-content-center w-100 ml-0">
                    <Col md={8}>
                      <LazyLoad height={300}>
                        <img className="circled_image rounded-circle h-100" src={Bird}></img>
                      </LazyLoad>
                    </Col>
                </Row>
                <Row className="pt-3 w-100 ml-0">
                    <Col md={12} className="text-center">
                        <h4 className="lightTextNoMargin pt-4">Our Core Principles</h4>
                    </Col>
                </Row>
                <Row className="justify-content-center pt-2 w-100 ml-0">
                    <Col md={8} className="text-center">
                        <p className="lightTextNoMargin">Understand customers' present as well as future needs and satisfy them. Focus on bringing
                            solutions, not more problems.</p>
                    </Col>
                </Row>
            </Col>
        </Row>
      </Container>
      

    </div>
  );
}

export default About;
