import React from 'react';
import { Carousel,  Card, Container, Row, Col } from 'react-bootstrap'
import { LazyImageProvider } from "../LazyImage/lazyImageContext"; //
import LazyImage from '../LazyImage/lazyImage'
import '../styling/home.css'
import { useHistory, Link } from 'react-router-dom';
import JoggingTrack from '../images/Kingsley/jogging_track.jpg'
import OmoroseCar from '../images/Omorose/18.jpg'
import homePagePhoto from '../images/Kingswood/carousel4.jpg'
import KingsleyCam from '../images/Kingsley/Cam_01.jpg'
import KingstonCardImage from '../images/Kingston/top_garden.jpg'
import KingroseCardImage from '../images/Kingrose/Corner_05_465.jpg'
import FarmvilleCardImage from '../images/Farmville/bird_view.jpg'
import KingswoodEntrance from '../images/Kingswood/kingswood_entrance.jpg'
import {Helmet} from 'react-helmet'
import LazyLoad from 'react-lazyload';
import ReactGA from 'react-ga';
import Fade from 'react-reveal/Fade'
import Pulse from 'react-reveal/Pulse'

import Ripples from 'react-ripples'
ReactGA.initialize('UA-135620216-1');
ReactGA.pageview(window.location.pathname + window.location.search);

/**
 * 
                        <LazyImageProvider>
                          <LazyImage style={{width: '100%'}} aspectRatio={[4, 3]} src={FarmvilleCardImage}></LazyImage>
                        </LazyImageProvider>

                        
                        
                    
 */


const images = [
  homePagePhoto, JoggingTrack, OmoroseCar
];

const mediumImages = [
  homePagePhoto, JoggingTrack, OmoroseCar
]

const mobileImages = [
  homePagePhoto, JoggingTrack, OmoroseCar
]


function Home() {

  const history = useHistory();

  const goToKingswood = () => {
    setTimeout(routeKingswood, 1500);
  }

  const routeKingswood = () => {
    history.push("/kingswood");
  }

  const delayRouteChange = () => {
    setTimeout(routeChange, 1500);
  }

  const routeChange = () => {
    history.push("/kingsley");
  }
  

  return (
    
    <div style={{backgroundColor: '#f2f2f2'}} className="Home">
          <Helmet>
          <meta description="Leading Real-Estate Developers in Surat, Gujarat." />
            <title>
              Vaishnodevi Realty
            </title>
          </Helmet>

      <LazyImageProvider>
          <Carousel style={{marginTop: '5em'}} interval={8000} className="bigCarousel">
            {images.map((image, index) => (
                <Carousel.Item>
                  <LazyImage aspectRatio={[21, 10]} src={image} key={index} mediumSrc={mediumImages[index]} mobileSrc={mobileImages[index]} />
                </Carousel.Item>
              ))}
          </Carousel>
      </LazyImageProvider>
          

        <div className="darkBlock">
          <div id="kingsley" className="container-fluid pt-5">

            <div style={{overflow:'hidden'}} className="row justify-content-around w-100 pt-5 ml-0 align-items-center">
              <div className="col-md-6 p-0">
                <Fade bottom>
                  <h3 className="headingKingsley text-center">Kingswood</h3>
                  <p className="darkText text-justify border-right-0">Kingswood is a project comprising of 2 & 3 bhk luxurious flats. Here, you will be astonished to find out about its spaciousness at such an appealing price.</p>
                  <p style={{marginTop: '0px', paddingTop: '0px'}} className="darkText text-justify border-right-0">Not only that, at Kingswood you will be surrounded by features to make every moment a pleasure to live and enjoy life in a gated complex with state-of-the-art security.</p>
                  <p style={{marginTop: '0px', paddingTop: '0px'}} className="darkText text-justify border-right-0">Book now to get the best price that we offer.</p>
                  <div className="row w-100 mb-2">
                    <div style={{paddingLeft: '40%', paddingRight: '40%'}} className="col-md-4 justify-content-around">
                      <Ripples color="#f2f2f2" during={1200}>
                        <button className="btn btn-primary explore_more" onClick={goToKingswood}>Explore</button>
                      </Ripples>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-4 p-0">
                <Fade bottom>
                  <LazyLoad height={300}>
                    <img id="#kingsleyHomeImage" className="smallImage pl-3 pr-3" src={KingswoodEntrance}/>
                  </LazyLoad>
                  <p className="darkText mt-4 p-0 d-block text-center">Guj Rera Reg. number :-
                    PR/GJ/SURAT/SURAT CITY/SUDA/RAA08230/190321</p>
                </Fade>
              </div>
            </div>

            <div style={{overflow: 'hidden'}} className="row justify-content-around w-100 pb-5 ml-0 align-items-center">
              <div className="col-md-4 p-0">
                <Fade bottom>
                  <LazyLoad height={300}>
                    <img id="#kingsleyHomeImage" className="smallImage pl-3 pr-3" src={KingsleyCam}/>
                  </LazyLoad>
                  <p className="darkText mt-4 p-0 d-block text-center">Guj Rera Reg. number :-
                    PR/GJ/SURAT/CHORASI/SUDA/RAA05756/190719</p>
                </Fade>
              </div>
              <div className="col-md-6 p-0">
                <Fade bottom>
                  <h3 className="headingKingsley text-center">Kingsley</h3>
                  <p className="darkText text-justify border-right-0">Kingsley is your chance to live a lavish life at
                  the most affordable rate. We understand that it can be overwhelming to select the best home for yourself in
                  today's world where you have numerous options available on the hand. That is why our staff is dedicated to
                  help you make the right
                  choice, because life is too short to regret later.</p>
                  <p style={{marginTop: '0px', paddingTop: '0px'}} className="darkText text-justify border-right-0">This is the place
                  where you could possibly discover your dream home. You are always welcomed to know more about Kignsley.</p>
                  <p style={{marginTop: '0px', paddingTop: '0px'}} className="darkText text-justify border-right-0">Book now to get the best price that we offer.</p>
                  <div className="row w-100">
                    <div style={{paddingLeft: '40%', paddingRight: '40%'}} className="col-md-4 justify-content-around">
                      <Ripples color="#f2f2f2" during={1200}>
                        <button className="btn btn-primary explore_more" onClick={delayRouteChange}>Explore</button>
                      </Ripples>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
            
          </div>            
        </div>
        <div className="lightBlock justify-content-center">
            <Container className="pb-5" fluid>
                <Row className="w-100 pl-3">
                <Col md={true}>
                  <Pulse>
                    <Card className="mt-5 lightBackground" style={{ width: '100%' }}>
                      <Link to="/kingrose2">
                        <LazyLoad height={'100%'}>
                          <img className="w-100" src={KingroseCardImage}/>
                        </LazyLoad>
                        <Card.Body className="lightText">
                          <Card.Title>Kingrose Villa 2</Card.Title>
                          <Card.Text className="text-uppercase">
                            A plotting scheme dedicated for people planning to build their dream home.
                          </Card.Text>
                        </Card.Body>
                      </Link>
                    </Card>
                  </Pulse>
                  </Col>
                  <Col md={true} className="mt-5">
                  <Pulse>
                    <Card className="mt-5 lightBackground" style={{ width: '100%'}}>
                      <Link to="/kingston">
                        <LazyLoad height={'100%'}>
                          <img className="w-100" src={KingstonCardImage}/>
                        </LazyLoad>
                        <Card.Body className="lightText">
                          <Card.Title>Kingston</Card.Title>
                          <Card.Text className="text-uppercase">
                            It's the noble concept to live nature. It's none other than your kingston.
                          </Card.Text>
                        </Card.Body>
                      </Link>
                    </Card>
                  </Pulse>
                  </Col>
                  <Col md={true}>
                  <Pulse>
                    <Card className="mt-5 lightBackground" style={{ width: '100%' }}>
                      <Link to="/farmville">
                        <LazyLoad height={'100%'}>
                          <img className="w-100" src={FarmvilleCardImage}/>
                        </LazyLoad>
                        <Card.Body className="lightText">
                          <Card.Title>Vaishnodevi Farmville</Card.Title>
                          <Card.Text className="text-uppercase">
                            Take some time off from your busy life and come enjoy the tranquility of nature.
                          </Card.Text>
                        </Card.Body>
                      </Link>
                    </Card>
                  </Pulse>
                  </Col>
                </Row>
            </Container>
        </div>
        <div style={{padding: '0px'}} className="lightBlock">
          <div className="row justify-content-center w-100">
            <div className="col-lg-12">
              <h3 className="lightText text-center mt-0">Office Location</h3>
            </div>
          </div>
        
          <div className="container pb-5">
            <div style={{overflow: 'hidden'}} className="row justify-content-center m-0 pb-5 w-100">
              <div className="col-md-12 map mx-0">
                <LazyLoad height={450}>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3859.5310691732907!2d72.77519771495783!3d21.228024086321664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04db67030ca8d%3A0xc9f38d736ead6a4d!2sKingswood!5e1!3m2!1sen!2sus!4v1656598819205!5m2!1sen!2sus"
                        width="100%" height="450px" frameborder="0" style={{border:'2px solid #0154ab'}} allowfullscreen></iframe>
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Home;
